@import 'css/colors';
@import 'css/border-radius';
@import 'css/spacings';
@import 'src/js/design-system/Text/Text.module.scss';
@import 'src/js/design-system/Form/TextField/TextFieldContainer.module.scss';

.inputField {
  @include default;
  padding: $spacing-12;
  resize: none;
  white-space: normal;
  overflow-y: auto;

  @include withNotStates {
    @include active;
  }

  &--error {
    .inputField {
      @include error;
    }
  }

  &--disabled {
    @include disabled;
  }

  &--readonly {
    @include readonly;
  }

  &__subText {
    @include subText;

    display: flex;
    gap: $spacing-16;
    justify-content: space-between;

    &__counterText {
      flex-shrink: 0;
      margin-left: auto;
    }
  }
}

.inputWrapper {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
}
