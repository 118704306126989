@import 'css/colors';

.roleLabel {
  padding: 2px 4px;
  background-color: $gray2;
  color: white;
  text-transform: uppercase;
  border-radius: 2px;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 1.2;
  white-space: nowrap;
}
