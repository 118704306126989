@import 'css/colors';
@import 'css/spacings';
@import 'css/border-radius';

@mixin leftBorder {
  &::before {
    content: '';
    position: absolute;
    width: 2px;
    top: 0;
    left: -$spacing-24 - 1px;
    height: 100%;
    background-color: $primary-gray-25;
  }
}

.waypoint {
  position: absolute;
  left: -$spacing-40;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-4;
  border-radius: $border-radius-circle;
  width: 32px;
  height: 32px;

  &.hype-info,
  &.hype-danger {
    outline: $spacing-4 solid $neutral-full-white;

    > * {
      outline: none;
      background-color: transparent;
    }
  }

  &.hype-info {
    background-color: $secondary-blue-600;
  }

  &.hype-danger {
    background-color: $system-red-600;
  }

  &.basic-end &__dot {
    background-color: $primary-gray-500;
  }

  &__dot {
    width: 12px;
    height: 12px;
    background-color: $neutral-full-white;
    border: 2px solid $primary-gray-500;
    border-radius: $border-radius-circle;
  }

  & > * {
    outline: $spacing-4 solid $neutral-full-white;
    background-color: $neutral-full-white;
  }
}

.activity {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: $spacing-40;
  padding-bottom: $spacing-24;

  &:first-child {
    .activity__primaryContent {
      &::before {
        height: 50%;
        top: 50%;
      }
    }
  }

  &:last-child {
    .activity__primaryContent {
      &::before {
        height: 50%;
      }
    }

    .activity__secondaryContent {
      &::before {
        display: none;
      }
    }
  }
}

.activity__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.activity__primaryContent,
.activity__secondaryContent {
  @include leftBorder;
  position: relative;
}

.activity__secondaryContent {
  &::before {
    height: calc(100% + $spacing-24);
  }
}

.activity__link {
  display: inline-block;
  color: $secondary-blue-600;
  cursor: pointer;
}
