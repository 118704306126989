@import 'css/colors';
@import 'css/spacings';

.tab {
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0;
  gap: $spacing-8;
  background-color: $platform-gray-300;
  color: $platform-gray-700;
  transition: all 0.15s ease-in-out;

  &:first-of-type:not(.active) {
    box-shadow: inset -1px -1px 8px rgba(0, 0, 0, 0.05);
  }

  &:last-of-type:not(.active) {
    box-shadow: inset 1px -1px 8px rgba(0, 0, 0, 0.05);
  }

  &:hover {
    background-color: $platform-gray-200;
  }

  &:focus,
  &:active:focus {
    box-shadow: none;
  }

  &.active {
    background-color: $platform-gray-100;
    color: $platform-gray-900;
  }
}
