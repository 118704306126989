@import 'css/colors';
@import 'css/mixins';
@import 'css/site-variables';
@import 'css/spacings';

.dropdown {
  &:global(.show) {
    position: static;
  }

  .currentSelections {
    font-weight: normal;
    white-space: normal;
  }

  .dropdownToggle {
    width: 100%;
    padding: 0;
    position: relative;

    > :global(.form-control) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .dropdownMenu {
    max-height: 480px;
    overflow: auto;
  }

  .itemToggleBtn {
    position: absolute;
    right: $spacing-4;
    top: $spacing-4;
    z-index: 1000;
    padding: $spacing-4;
    display: flex;
  }

  .item {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0;

    &.selected {
      > .itemLabel {
        font-weight: bold;

        .checkmark {
          visibility: visible;
        }
      }
    }

    &.active,
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  .itemLabel {
    --indent: 0;

    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    padding: 0 8px 0 3px;
    padding-left: calc(8px + 20px * var(--indent));
    cursor: pointer;

    &:not(:last-child) {
      padding-right: 34px;
    }

    &.active,
    &:hover,
    &:focus {
      background-color: $platform-gray-200;
    }
  }

  .checkmark {
    visibility: hidden;
    margin-right: $spacing-4;
  }

  .accordion {
    width: 100%;
  }
}
