@import 'css/border-radius';
@import 'css/colors';
@import 'src/js/design-system/Text/Text.module.scss';

.placeholder {
  @keyframes loadingPlaceholder {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }

  @include text-body-main;
  color: transparent;
  border-radius: $border-radius-2;
  animation-duration: 1500ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingPlaceholder;
  animation-timing-function: linear;
  background: $platform-gray-200;
  background-image: linear-gradient(
    to right,
    $platform-gray-200 0%,
    $platform-gray-300 20%,
    $platform-gray-200 40%,
    $platform-gray-200 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  width: 100%;
}
