@import 'css/site-variables';
@import 'css/colors';
@import 'css/border-radius';

.searchBox {
  display: flex;
  justify-content: space-between;
  border: 1px solid $platform-gray-200;
  border-radius: $border-radius-4;

  &:hover {
    background-color: transparent !important;
    border-color: $platform-gray-300;
  }

  &:focus-within,
  &:active {
    background-color: transparent !important;
    border-color: $gray4;
  }
}
