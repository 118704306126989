@import 'css/colors';
@import 'css/mixins';
@import 'css/spacings';

.mainText {
  font-weight: 500;
  min-width: fit-content;
}

.subText {
  margin-left: $spacing-4;
  @include ellipsis;
}

.verifyCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $oc-primary;
  margin-right: $spacing-4;
}
