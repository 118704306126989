@import 'css/colors';
@import 'css/border-radius';
@import 'css/spacings';
@import 'css/shadows';
@import 'css/site-variables';

.button {
  border: none;
  border-radius: $border-radius-4;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: $base-transition-time;
  gap: $spacing-8;

  &:disabled {
    cursor: not-allowed;
  }
}

// button size
.small {
  height: 32px;
}

.medium {
  height: 40px;
}

.large {
  height: 48px;
}

// variants
@mixin activeFilledBtn {
  background-color: $oc-primary-active;
}

.filled {
  background-color: $oc-primary;
  color: $neutral-full-white;

  &:hover {
    background-color: $oc-primary-hover;
  }

  &:active,
  &.active {
    @include activeFilledBtn;
  }

  &:disabled {
    background-color: $oc-primary-disabled;
  }
}

@mixin activeOutlinedBtn {
  background-color: $platform-gray-200;
  box-shadow: $shadow-inset-700;
  color: $platform-gray-900;
}

.outlined {
  background-color: transparent;
  box-shadow: $shadow-inset-300;
  color: $platform-gray-900;

  &:hover {
    background-color: $platform-gray-100;
    box-shadow: $shadow-inset-400;
    color: $platform-gray-900;
  }

  &:active,
  &.active {
    @include activeOutlinedBtn;
  }

  &:disabled {
    background-color: transparent;
    box-shadow: $shadow-inset-200;
    color: $platform-gray-500;
  }
}

@mixin activeGhostBtn {
  background-color: $platform-gray-300;
  color: $platform-gray-900;
}

.ghost {
  background-color: transparent;
  color: $platform-gray-700;

  &:hover {
    background-color: $platform-gray-200;
    color: $platform-gray-900;
  }

  &:active,
  &.active {
    @include activeGhostBtn;
  }

  &:disabled {
    background-color: transparent;
    color: $platform-gray-500;
  }
}

// loading spinner
.spinnerContainer {
  display: grid;

  .spinnerContent {
    visibility: hidden;
    grid-area: 1 / 1;
  }

  .spinnerIcon {
    grid-area: 1 / 1;
    display: flex;
    justify-content: center;

    .spinner {
      animation: spin 1000ms linear infinite;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
