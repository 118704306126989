@import 'css/bs-overrides';
@import 'css/colors';
@import 'css/mixins';
@import 'css/responsive';
@import 'css/site-variables';
@import 'css/spacings';

.dropdown:global(.show) {
  position: static;
}

.dropdown {
  min-height: 32px;
  width: auto;
  min-width: 140px;

  .toggle {
    position: relative;
    padding: 0;
    width: 100%;
    display: block;
    text-align: left;
    border: none;
    line-height: 16px;
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu:global(.show) {
    display: flex;
    flex-direction: column;
    min-width: inherit;
    padding: 8px 0;
    border: 1px solid $input-border-color;
  }

  .option {
    font-weight: normal;
    width: 100%;
    justify-content: flex-start;
    padding: 0 8px 0 3px;
    height: 32px;
    min-height: 32px;
    line-height: 16px;

    &:focus-visible,
    &:focus {
      outline: none;
      background-color: transparent;
    }
  }

  .option:focus,
  .option:hover {
    background-color: $platform-gray-200;
  }

  .option.selected {
    font-weight: bold;

    .checkmark {
      visibility: visible;
    }
  }

  .checkmark {
    visibility: hidden;
    margin-right: $spacing-4;
  }

  .currentSelections {
    font-weight: normal;
    white-space: normal;
    display: flex;
    flex-wrap: wrap;
    padding-right: 20px;
  }

  .formControl {
    padding: 0 !important;
    border: 1px solid $input-border-color;

    > div {
      max-width: 100% !important;
    }
  }

  .searchBox {
    background-color: $platform-gray-200;
    height: 32px;
    border: none;
    border-radius: 0.25rem;
    @include ellipsis;

    input {
      padding-left: 8px;
      color: $platform-gray-500;
      @include ellipsis;
    }
  }

  .noOptions {
    color: $platform-gray-500;
    text-align: center;
    font-size: 12px;
  }
}
