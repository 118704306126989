@import 'css/spacings';
@import 'css/site-variables';

.modal:global(.modal) {
  z-index: $z-index-max;
}

.wrapper {
  max-width: 472px;
}

.header {
  padding: $spacing-32 $spacing-24 0 $spacing-24;
  border-bottom: none;
}

.content {
  padding: $spacing-4 $spacing-24 $spacing-24;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: $spacing-12;
  padding: 0 $spacing-24 $spacing-24;
  border-top: none;
}

.backdrop:global(.modal-backdrop) {
  z-index: $z-index-modal + 1;
}

.confirmationInputWrapper {
  margin-top: $spacing-16;
}
