@import 'css/spacings';
@import 'css/colors';

.deck {
  border: 1px solid $platform-gray-100;
  border-radius: 0 $spacing-4 $spacing-4;
  padding: $spacing-16 $spacing-24;
  display: flex;
  justify-content: space-between;
}

.card {
  height: 172px;
  width: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-8;
  align-items: center;

  svg {
    color: $platform-gray-400;
  }

  .title {
    width: 112px;
    text-align: center;
  }

  &:hover {
    background-color: $platform-blue-100;
    border: 1px solid $oc-primary-hover;
    border-radius: $spacing-4;
    svg {
      color: $oc-primary-hover;
    }
  }
}

.upload {
  justify-content: center;
  width: 100%;
}
