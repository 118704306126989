@import 'css/colors';
@import 'css/site-variables';
@import 'css/border-radius';

.inputContainer {
  position: relative;
}

.inputContainer .clearBtn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-left: 1px solid $gray6;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;

  > input {
    &:hover {
      border-color: $input-border-color;
    }
  }
}

.suggestionsContainer {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 3;
}

.suggestionsContainerOpen {
  display: block;
}

.suggestionsList {
  position: relative;
  top: 4px;
  padding: 8px 0 8px 0;
  background-color: white;
  width: 100%;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-4;
  border: solid 1px;
  border-color: $input-border-color;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 14px;

  li {
    &:hover,
    &:focus {
      background-color: $platform-gray-200;
    }
  }

  button {
    border-top: none;

    &:hover,
    &:focus {
      border-top: none;
    }
  }
}

.suggestionHighlighted {
  background-color: $platform-gray-200;
}

.suggestion {
  list-style: none;
  cursor: pointer;
}

.suggestionFirst {
  border-top: none;
}
