@import 'css/colors';

.titleContainer {
  display: flex;
}

.subTitle {
  color: $primary;
  padding-left: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
