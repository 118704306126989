@import 'colors';
@import 'border-radius';

.dropdown-menu {
  padding: 0;
  border: 1px solid $platform-gray-200;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-4;

  hr {
    background-color: $platform-gray-200;
    opacity: 1;
    margin: 8px 0;
  }
}

.dropdown-toggle {
  color: $gray2;
  font-size: 14px;
  line-height: 1;

  &:before,
  &:after {
    display: none !important;
  }

  &:hover,
  &:focus {
    color: $gray2;
    box-shadow: none !important;
  }
}

.dropdown-toggle:disabled {
  opacity: 1;
  background-color: $gray10;
  border: none;
  box-shadow: none;
}

.dropdown-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  padding: 8px;
  color: $gray2;
  background-color: white;

  &:hover,
  &:focus {
    color: $gray2;
    background-color: $platform-gray-100;
  }
}

.table tr {
  color: $gray2;
  border-color: $input-border-color;

  th {
    height: 46px;
    font-size: 12px;
    font-weight: normal;
  }

  td {
    height: 66px;
    font-size: 14px;
    color: $gray2;
  }

  td,
  th {
    padding: 0 16px;
    vertical-align: middle;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.modal {
  padding-right: 0 !important;
}

.modal-content {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 32px;
}

.modal-dialog {
  max-width: 480px;
  margin: auto;
}

.modal-body {
  padding: 0;
}

.modal-header {
  margin: 0 0 32px 0;
  padding: 0 0 16px 0;
}

.form-control {
  font-size: 0.875rem;
}

.form-control:disabled {
  background-color: $gray10;
  border: none;
  box-shadow: none;
}
