@import 'css/colors';
@import 'css/spacings';

.suggestion {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  width: 100%;
}

.topSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: $spacing-4;
}

.badges {
  display: flex;
  gap: $spacing-4;
}

.verifyCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $oc-primary;
  margin-right: $spacing-4;
}

.description {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &.collapsed {
    margin-top: -$spacing-4;
  }
}
