@import 'css/colors';
@import 'src/js/design-system/Form/Select/ListBox.module.scss';

.selectContainer {
  position: relative;
  user-select: none;
  width: 100%;
  flex: 1;

  .select {
    user-select: none;

    &:not(:disabled) {
      cursor: pointer;
      // revert some "readonly" styles from TextField
      color: $neutral-full-black;
      background-color: $neutral-full-white;
      box-shadow: inset 0 0 0 1px $primary-gray-200;

      &:hover {
        box-shadow: inset 0 0 0 1.25px $primary-gray-500;
      }

      &:active,
      &:focus {
        box-shadow: inset 0 0 0 1.5px $primary-gray-600;
      }
    }
  }

  .suggest {
    cursor: auto;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .menu {
    @include listbox;
    width: 100%;
    top: 100%;

    &.suggestMenu {
      min-width: 300px;
    }

    &.top {
      top: unset;
      bottom: 100%;
    }

    &.right {
      right: 0;
    }

    .sticky {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: $neutral-full-white;
    }

    .option {
      @include listboxItem;
    }

    .actionButton {
      padding: $spacing-4 $spacing-12;

      > button {
        width: 100%;
      }
    }
  }
}

.appendIcon {
  pointer-events: none;
}
