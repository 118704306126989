@import 'colors';
@import 'bootstrap';
@import 'bs-overrides';
@import 'responsive';
@import 'src/js/design-system/Text/Text.module.scss';
@import 'font';

body {
  @include text-body-main;
  color: $text-main;
  font-family: $body-font-family;

  #finch-connect-iframe {
    z-index: 10000 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-background-clip: text;
  background-clip: text;
}

li {
  list-style-type: none;
}

button {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  text-align: inherit;
  background-color: transparent;
}

[tabindex] {
  outline: none;
}

.container-xl {
  --bs-gutter-x: 1rem;
}

@include media-breakpoint-up(md) {
  .container-xl {
    --bs-gutter-x: 2rem;
  }
}

@include media-breakpoint-up(lg) {
  .container-xl {
    --bs-gutter-x: 3rem;
  }
}
