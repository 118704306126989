@import 'css/site-variables';
@import 'css/colors';
@import 'css/responsive';

.syncBannerBackground {
  display: flex;
  align-items: center;
  min-height: 75px;
  padding: 16px 0;
}

//syncVariants:
.in_progress {
  background: linear-gradient(270deg, $oc-primary, #00abf2, $oc-primary);
  background-size: 300% 300%;
  animation: syncAnimation 2s ease infinite;
  transform: translateZ(0); //force gpu to run animation
}
.success {
  background: linear-gradient(270deg, $oc-primary, #14dea2, $oc-primary);
  background-size: 300% 300%;
  animation: singleAnimation 1s ease-in-out;
  transform: translateZ(0); //force gpu to run animation
}
.failed {
  background: #dc3545;
  background: linear-gradient(270deg, #dc3545, #e97e4c, #dc3545);
  background-size: 300% 300%;
  animation: singleAnimation 1s ease-in-out;
  transform: translateZ(0); //force gpu to run animation
}
.disconnected {
  background: #dc3545;
  background: linear-gradient(270deg, #dc3545, #e97e4c, #dc3545);
  background-size: 300% 300%;
  animation: singleAnimation 1s ease-in-out;
  transform: translateZ(0); //force gpu to run animation
}

@keyframes syncAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes singleAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.syncBannerWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;

  a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
    padding-left: 4px;
  }

  a:hover {
    color: $gray6;
  }
}

.columnOne {
  flex: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  margin-right: 8px;
}

.icon.in_progressAnimation {
  animation: spin 1.5s linear infinite;
  transform: translateZ(0); //force gpu to run animation
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.columnTwo {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cta {
  color: white !important;
  font-weight: 400;
  background: transparent;
  border: none;
  white-space: nowrap;
}

.cta:hover {
  color: $gray6 !important;
}

.cta:focus {
  border: none;
}
