/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/offcanvas';

// Utilities
@import '../../node_modules/bootstrap/scss/utilities/api';
// scss-docs-end import-stack
