@import 'css/colors';
@import 'css/spacings';

.topHeader {
  margin-bottom: $spacing-32;
}

.mainContent {
  display: grid;
  grid-auto-flow: column;
  gap: $spacing-16;
  align-items: center;
}

.headingAndSubheading {
  display: grid;
  row-gap: $spacing-8;
}

.headingContainer {
  display: grid;
  grid-auto-flow: column;
  place-content: start;
  align-items: center;
  gap: $spacing-16;
}

.actionButtonContainer {
  display: grid;
  grid-auto-flow: column;
  gap: $spacing-16;
  align-self: center;
  justify-self: flex-end;
}

.additionalContent {
  display: flex;
  justify-content: flex-end;
}

.subheading {
  color: $platform-gray-700;
  max-width: 750px;
}
