@import 'css/colors';

.board {
  background: $gray4;
  padding: 48px 48px 0 48px;
  border-radius: 10px 10px 0 0;
}

.modal .modalContent {
  padding: 0;
  border-radius: 12px;
}

.modal .modalDialog {
  width: 596px;
  max-width: calc(100vw - 40px);
}

.welcomeBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
}

.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  color: $platform-gray-700;
}

.message {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $platform-gray-700;
  margin: 16px 0;
  width: 100%;

  .scenarioName {
    font-weight: bold;
  }
}

.companyResemble {
  border-radius: 10px 10px 0 0;
  height: 280px;
}

.bodyCompany {
  border-radius: 0;
  height: 216px;
}
