@import 'colors';

$shadow-100: 0px 2px 4px -2px rgba($shadow-drop-shadow, 0.12),
  0px 4px 4px -2px rgba($shadow-drop-shadow, 0.08);
$shadow-200: 0px 4px 6px -4px rgba($shadow-drop-shadow, 0.12),
  0px 8px 8px -4px rgba($shadow-drop-shadow, 0.08);
$shadow-300: 0px 6px 8px -6px rgba($shadow-drop-shadow, 0.12),
  0px 8px 16px -6px rgba($shadow-drop-shadow, 0.08);
$shadow-400: 0px 6px 12px -6px rgba($shadow-drop-shadow, 0.12),
  0px 8px 24px -4px rgba($shadow-drop-shadow, 0.08);
$shadow-500: 0px 6px 14px -6px rgba($shadow-drop-shadow, 0.12),
  0px 10px 32px -4px rgba($shadow-drop-shadow, 0.1);
$shadow-600: 0px 8px 18px -6px rgba($shadow-drop-shadow, 0.12),
  0px 12px 42px -4px rgba($shadow-drop-shadow, 0.12);
$shadow-700: 0px 8px 22px -6px rgba($shadow-drop-shadow, 0.12),
  0px 14px 64px -4px rgba($shadow-drop-shadow, 0.12);
$shadow-800: 0px 8px 28px -6px rgba($shadow-drop-shadow, 0.12),
  0px 18px 88px -4px rgba($shadow-drop-shadow, 0.14);
$shadow-900: 1px 0px 8px rgba($primary-gray-800, 0.05), -1px 0px 8px rgba($primary-gray-800, 0.05);

$shadow-inset-200: inset 0px 0px 0px 1px $primary-gray-200, inset 0px 0px 0px 1px $primary-gray-200;
$shadow-inset-500: inset 0px 0px 0px 1px $primary-gray-500, inset 0px 0px 0px 1px $primary-gray-500;
$shadow-inset-900: inset 1px 0px 8px rgba($primary-gray-800, 0.05),
  inset -1px 0px 8px rgba($primary-gray-800, 0.05);

// new shadow varialbles using new palette
$shadow-inset-300: inset 0px 0px 0px 1px $platform-gray-300,
  inset 0px 0px 0px 1px $platform-gray-300;
$shadow-inset-400: inset 0px 0px 0px 1px $platform-gray-400,
  inset 0px 0px 0px 1px $platform-gray-400;
$shadow-inset-700: inset 0px 0px 0px 1px $platform-gray-700,
  inset 0px 0px 0px 1px $platform-gray-700;

$shadow-border: inset 0 0 0 1px;
$shadow-border-medium: inset 0 0 0 1.5px;
$shadow-border-thick: inset 0 0 0 2px;
