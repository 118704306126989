@import 'css/colors';
@import 'css/spacings';

.bulkEditForm {
  flex: auto;
  padding: $spacing-8;
  background-color: $primary-gray-900;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(136px);
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1050;

  &.open {
    transform: translateY(0);
  }

  .formContent {
    display: flex;
    height: 48px;
    padding: 0 $spacing-24;

    .formText,
    .fields,
    .actions {
      display: flex;
      gap: $spacing-16;
      align-items: stretch;
      flex: auto;
      height: 100%;
      line-height: 48px;
    }

    .actions {
      display: flex;
      align-items: center;
      padding: $spacing-4 0;
    }

    .buttons {
      display: flex;
      gap: $spacing-16;
      align-items: center;
      flex: auto;
    }

    .formText {
      flex-grow: 0;
      margin-right: $spacing-16;
    }

    .buttons {
      justify-content: flex-end;

      .cancelButton {
        color: $neutral-full-white;

        &:hover {
          color: initial;
        }
      }

      .submitButton {
        color: $neutral-full-white;

        &:hover {
          color: initial;
        }
      }
    }
  }

  .formActions {
    display: none;
  }
}
