@import 'css/spacings';
@import 'css/site-variables';

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - $navbar-height);

  .backButtonContainer {
    margin-top: $spacing-32;
  }

  .body {
    display: flex;
    gap: $spacing-64;
    padding-bottom: $spacing-128;
  }
}
