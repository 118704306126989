@import 'css/colors';
@import 'css/spacings';
@import 'css/site-variables';

.wrapper {
  display: flex;
  align-items: center;
  background-color: $platform-gray-700;
}

.tab {
  font-size: 14px;
  font-weight: 400;
  margin-right: $spacing-8;
  padding: $spacing-10 $spacing-12 $spacing-10 $spacing-8;
  line-height: 24px;
  color: $platform-gray-400;
  display: flex;
  align-items: center;
  transition: all $base-transition-time;

  &:not(.selected):hover {
    background-color: $platform-gray-200;
    color: $platform-gray-900;
    .badge {
      color: $oc-primary;
    }
    svg {
      color: $oc-primary;
    }
  }

  &.selected {
    background-color: $neutral-full-white;
    color: $platform-gray-900;
    .badge {
      color: $oc-primary;
    }
    svg {
      color: $oc-primary;
    }
  }

  &.selected.upgrade {
    background-color: $upgrade;
    color: $platform-gray-900;
    svg {
      color: $platform-gray-900;
    }
  }

  &.selected.withBanner {
    background-color: $oc-primary;
    color: $neutral-full-white;
    svg {
      color: $neutral-full-white;
    }
  }

  &.featured {
    background-color: $oc-primary;
    color: $neutral-full-white;
  }

  &.selected.featured {
    background-color: $neutral-full-white;
    color: $platform-gray-900;
    svg {
      color: $oc-primary;
    }
  }

  &.totalRewards {
    margin-right: 0;
  }

  .badge {
    position: relative;
    bottom: $spacing-4;
    margin-left: $spacing-2;
    color: $neutral-full-white;
  }

  svg {
    margin-right: $spacing-10;
  }
}
