@import 'js/design-system/Button/Button.module.scss';

.open,
.open:hover {
  @include activeGhostBtn;
}

.tooltipItem {
  width: 100%;
}
