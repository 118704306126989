@import 'css/colors';
@import 'css/spacings';
@import 'css/border-radius';

.default,
.avatar,
.logo {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.default {
  .image {
    border: 1px solid $platform-gray-200;
    border-radius: $border-radius-4;
  }
}

.avatar {
  align-items: center;

  .image {
    max-width: 100%;
    width: $spacing-56;
    height: $spacing-56;
    border-radius: 100%;
  }

  .buttons {
    flex-direction: column;
  }
}

.logo {
  .image {
    max-width: 100%;
    width: 152px;
  }

  .buttons {
    flex-direction: column;

    .button {
      width: 152px;
    }
  }
}

.image {
  cursor: pointer;
  object-fit: cover;
}

.buttons {
  display: flex;
  margin-top: $spacing-8;
  gap: $spacing-8;

  .button {
    flex-grow: 1;
  }
}
