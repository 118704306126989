@import 'css/colors';
@import 'css/spacings';
@import 'src/js/design-system/Text/Text.module.scss';

.container {
  padding-top: $spacing-48;
  display: flex;
  flex-direction: column;
  flex: auto;
}

.heading {
  margin-bottom: $spacing-16;
}

.benchmarkingTable {
  flex: auto;
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: $spacing-32;
}
