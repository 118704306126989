@import 'css/colors';
@import 'css/spacings';
@import 'css/border-radius';
@import 'css/site-variables';
@import 'css/shadows';
@import 'src/js/design-system/Text/Text.module.scss';

.default {
  flex: auto;

  div[role='row']:not(.header):not(.rowgroupHeader) {
    padding-top: $spacing-8;
    padding-bottom: $spacing-8;
    display: flex;
    border-bottom: solid 1px $platform-gray-100;

    &.noBottomBorder {
      border-bottom: 0;
    }

    &.selected {
      background-color: $oc-secondary;
    }
  }

  div[role='rowgroup']:not(:last-child)
    div[role='row']:not(.header):not(.rowgroupHeader):last-child {
    border-bottom: none;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.header {
  @include text-misc-caption;
  padding-top: $spacing-8;
  padding-bottom: $spacing-8;
  color: $platform-gray-700;

  &.selectable > *:first-child {
    @include text-body-main;
  }

  &.notGrouped {
    border-bottom: solid 1px $platform-gray-300;
  }

  &.rowgroupHeader {
    background-color: $platform-gray-100;
    border-bottom: solid 1px $platform-gray-300;
    border-top: solid 1px $platform-gray-300;
    padding: $spacing-4 0;
  }
}

.tableControls {
  margin-bottom: $spacing-16;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableFilters {
  display: flex;
  align-items: center;
  gap: $spacing-16;
  padding: 0 $spacing-16;
  background-color: $platform-gray-100;
  height: 0;
  overflow: hidden;
  transition: height $base-transition-time $base-transition-function;

  &.open {
    height: 120px;
    margin-bottom: $spacing-16;
    overflow: unset;
  }

  > * {
    width: 300px;
  }
}
