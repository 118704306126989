@import 'css/spacings';

.noActiveRange {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    margin-bottom: $spacing-24;
  }

  .subtitle {
    margin-top: $spacing-8;
    max-width: 366px;
    text-align: center;
  }

  > button {
    margin-top: $spacing-32;
    width: 100%;
  }
}
