@import 'css/colors';
@import 'css/spacings';
@import 'css/border-radius';

.checkbox {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
  border: solid 2px $platform-gray-500;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:checked,
  &:indeterminate {
    border-color: $oc-primary;
    background-color: $oc-primary;
  }

  &:checked::before,
  &:indeterminate::before {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }

  &:checked::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='44 92 100 148 220 20' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  }

  &:indeterminate::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' viewBox='0 0 24 24' fill='%23FFFFFF'%3E%3Cg%3E%3Crect fill='none' fill-rule='evenodd' height='24' width='24'/%3E%3Crect fill-rule='evenodd' height='2' width='16' x='4' y='7'/%3E%3C/g%3E%3C/svg%3E");
  }

  &:disabled {
    cursor: initial;

    &:not(:checked):not(:indeterminate) {
      border-color: $platform-gray-200;
    }

    &:checked,
    &:indeterminate {
      border-color: $platform-blue-300;
      background-color: $platform-blue-300;
    }
  }
}
