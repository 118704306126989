@import 'css/spacings';
@import 'css/colors';

.wrapper:global(.modal-dialog) {
  max-width: 800px;
  width: 100%;
}

.wrapper .content {
  padding: $spacing-64;
}

.title {
  margin-bottom: $spacing-24;
}

.closeButton {
  position: absolute;
  right: $spacing-32;
  top: $spacing-32;
}

.list {
  padding-left: $spacing-12;
  margin-bottom: $spacing-0;

  &.noBullets {
    padding-left: 0;

    li {
      list-style-type: none;
    }
  }
}

.list .item {
  list-style: disc;
  padding-bottom: $spacing-24;
  color: $platform-gray-700;
}

.singleItem {
  margin-bottom: $spacing-0;
  padding-bottom: $spacing-24;
  color: $platform-gray-700;
}

.body {
  color: $platform-gray-700;
}
