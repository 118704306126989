@import 'css/colors';

.marketingContainer {
  height: 100%;

  .campaign {
    background-color: transparent;
    padding: 0;
  }
}
