@import 'css/colors';
@import 'css/border-radius';

.defaultPlaceholder {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: $primary-gray-50;
  border: 1px solid $platform-gray-200;
  border-radius: $border-radius-4;
  cursor: pointer;
}
