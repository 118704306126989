@import 'css/colors';
@import 'css/spacings';

.userContainer {
  border-top: 1px solid $platform-gray-200;
  padding-top: $spacing-12;
  margin-bottom: -$spacing-12;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .userCount {
    display: flex;
    align-items: center;
    gap: $spacing-4;
  }
}
