@import 'css/colors';

.companyResemble {
  max-width: 500px;
  width: 100%;
}

.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $gray2;
  border-radius: 10px 10px 0 0;
  padding: 16px 32px;
  width: 100%;
}

.body {
  border-radius: 0 0 10px 10px;
  background: white;
}

.logoAndName,
.fundingRange,
.numberEmployees,
.companyInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logoAndName {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: white;

  img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
}

.fundingRange,
.numberEmployees {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: white;
  padding-right: 8px;

  svg {
    width: 32px;
    height: 32px;
    padding: 4px;
  }
}
