@import 'css/border-radius';
@import 'css/colors';
@import 'css/spacings';
@import 'css/site-variables';
@import 'src/js/design-system/Form/Select/ListBox.module.scss';
@import 'src/js/design-system/Text/Text.module.scss';

.trigger {
  @include text-subheading-medium;
  color: $neutral-full-white;
  display: flex;
  align-items: center;
  gap: $spacing-8;
  border-radius: $border-radius-4;
  padding: $spacing-8;
  cursor: pointer;
  transition: box-shadow $base-transition-time;

  &:hover {
    box-shadow: inset 0 0 0 1px rgba($neutral-full-white, 0.3);
  }

  &:active,
  &.active {
    box-shadow: inset 0 0 0 1px rgba($neutral-full-white, 0.53);
  }
}

.upgrade,
.upgrade:hover {
  color: $platform-gray-400;

  .badge {
    margin-left: auto;
  }
}
