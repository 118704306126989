// DO NOT MODIFY

$spacing-0: 0px;
$spacing-1: 1px;
$spacing-2: 2px;
$spacing-4: 4px;
$spacing-6: 6px;
$spacing-8: 8px;
$spacing-10: 10px;
$spacing-12: 12px;
$spacing-14: 14px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-56: 56px;
$spacing-64: 64px;
$spacing-72: 72px;
$spacing-80: 80px;
$spacing-88: 88px;
$spacing-96: 96px;
$spacing-104: 104px;
$spacing-112: 112px;
$spacing-120: 120px;
$spacing-128: 128px;
$spacing-136: 136px;
$spacing-144: 144px;
$spacing-152: 152px;

:export {
  spacing-0: $spacing-0;
  spacing-1: $spacing-1;
  spacing-2: $spacing-2;
  spacing-4: $spacing-4;
  spacing-6: $spacing-6;
  spacing-8: $spacing-8;
  spacing-10: $spacing-10;
  spacing-12: $spacing-12;
  spacing-14: $spacing-14;
  spacing-16: $spacing-16;
  spacing-24: $spacing-24;
  spacing-32: $spacing-32;
  spacing-40: $spacing-40;
  spacing-48: $spacing-48;
  spacing-56: $spacing-56;
  spacing-64: $spacing-64;
  spacing-72: $spacing-72;
  spacing-80: $spacing-80;
  spacing-88: $spacing-88;
  spacing-96: $spacing-96;
  spacing-104: $spacing-104;
  spacing-112: $spacing-112;
  spacing-120: $spacing-120;
  spacing-128: $spacing-128;
  spacing-136: $spacing-136;
  spacing-144: $spacing-144;
  spacing-152: $spacing-152;
}
