@import 'css/spacings';

.list {
  display: flex;
  flex-direction: column;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-16 0;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: $spacing-16;
}

.rightColumn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $spacing-8;
}
