@import 'css/spacings';

.container {
  display: flex;
  justify-content: center;
  padding: $spacing-56 0;
  margin-top: $spacing-104;
}

.container__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 336px;
}

.icon {
  margin-bottom: $spacing-32;
}

.heading {
  margin-bottom: $spacing-8;
}

.subheading {
  text-align: center;
}
