@import 'css/colors';
@import 'css/spacings';
@import 'css/site-variables';

.wrapper {
  width: $activity-log-width;
  background-color: $neutral-full-white;
  box-shadow: -2px 0px 4px rgba($platform-blue-900, 0.12);
}

.header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: $spacing-8;
  background-color: $primary-gray-25;
  height: 48px;
  border-bottom: 1px solid $primary-gray-200;
}

.closeBtnWrapper,
.filterBtnWrapper {
  min-width: $spacing-32;
}

.footer {
  background-color: $primary-gray-25;
  border-top: 1px solid $primary-gray-200;
  padding: $spacing-16 $spacing-24 $spacing-16;
}

.lockedState {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-24;
  margin-top: 6vh;

  .icon {
    margin-bottom: $spacing-32;
  }

  .heading {
    margin-bottom: $spacing-8;
  }

  .subheading {
    text-align: center;
    margin-bottom: $spacing-32;
  }

  .upgradeBtn {
    width: 226px;
  }
}
