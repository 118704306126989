@import 'css/site-variables';
@import 'css/colors';
@import 'css/responsive';
@import 'css/border-radius';

.demoBannerBackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0;
  background-color: $tier-3;
  color: white;
}

.demoBannerWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.columnOne {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.columnTwo {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.columnThree {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include media-breakpoint-down(md) {
    margin-top: 10px;
  }
}

.header {
  font-weight: 700;
  font-size: 16px;
  text-align: center;

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

.text {
  font-size: 14px;
  text-align: center;
}

.icon {
  margin: 0px 8px 2px 0px;
  filter: $gray2-filter;
}

.cta {
  font-weight: 500;
  padding: 4px 12px;
  color: $platform-gray-700;
  background: white;
  border: none;
  border-radius: $border-radius-4;
  white-space: nowrap;
}

.cta:hover {
  background-color: $gray8 !important;
}

.cta:focus {
  border: none;
}
