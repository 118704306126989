@import 'css/colors';

.confirmButton {
  background-color: $tier-2;

  &:hover {
    background-color: darken($tier-2, 5%);
  }

  &:active {
    background-color: darken($tier-2, 10%);
  }
}
