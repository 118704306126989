.dropdown {
  min-width: 100%;
}

.dropdown:global(.show) {
  position: relative;

  :global(.dropdown-menu.show) {
    transform: none !important;
    inset: auto !important;
  }
}

.dropdown:not(.openUp):global(.show) {
  :global(.dropdown-menu.show) {
    top: calc(100% + 1px) !important;
  }
}

.dropdown.openUp:global(.show) {
  :global(.dropdown-menu.show) {
    bottom: calc(100% + 1px) !important;
  }
}
