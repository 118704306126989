@import 'css/colors';
@import 'css/border-radius';
@import 'css/spacings';
@import 'css/mixins';

div.inputGroup {
  margin: 0 auto;
}

.inputGroup .fileInput {
  min-width: 100%;

  label {
    position: relative;
    margin-bottom: 0;
  }

  .fileBox {
    display: none;
  }

  .innerText {
    display: -webkit-box;
    flex: 1;
    line-height: normal;
    overflow: hidden;
    max-height: 40px;
    padding: 0 8px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .clearable {
    display: flex;
    background-color: transparent;
    align-items: center;
    width: 100%;
    height: 100%;

    .clearableFileName {
      @include ellipsis;
      flex: 1 1;
      text-align: center;
      padding-right: $spacing-24;
    }

    .clearButton {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 48px;
      margin-right: -$spacing-24;

      &:hover {
        @include fillSvgColor($primary-gray-900);
      }
    }
  }
}
