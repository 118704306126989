@import 'css/colors';
@import 'css/spacings';

.wrapper {
  display: flex;
  align-items: center;
}

.helpText {
  margin-left: 20px + (2 * $spacing-8);
}

.label {
  display: flex;
  align-items: center;
}

.input {
  accent-color: $oc-primary;
  width: 20px;
  height: 20px;
  margin: $spacing-8;
}

.input:before {
  accent-color: $platform-gray-500;
}
