@import 'css/site-variables';
@import 'css/border-radius';
@import 'css/colors';
@import 'css/spacings';
@import 'src/js/design-system/Form/Select/ListBox.module.scss';
@import 'src/js/design-system/Text/Text.module.scss';

.companyDropdown {
  display: flex;
  flex-direction: column;
  transition: $base-transition-time;

  &__active {
    .companyDropdown__trigger {
      background-color: rgba($neutral-full-white, 0.2);

      &Label {
        color: $platform-gray-100 !important;
      }
      svg {
        transform: rotate(180deg);
        polyline {
          stroke: $platform-gray-100;
        }
      }
    }
  }

  &__multiScenarios:not(&__active) {
    &:hover {
      .companyDropdown__trigger {
        background-color: rgba($neutral-full-white, 0.15);

        &Label {
          color: $platform-gray-300 !important;
        }
        svg polyline {
          stroke: $platform-gray-300;
        }
      }
    }
  }

  &__trigger {
    display: flex;
    gap: $spacing-2;
    border-radius: $border-radius-4;
    padding: $spacing-2 $spacing-4;
    margin-left: -$spacing-2;

    &Wrap {
      display: inline-flex;
    }
  }
}

.menu {
  left: -$spacing-2 !important;
}
