@import 'css/border-radius';
@import 'css/colors';

.badgeWrapper {
  border-radius: $border-radius-2;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
