@import 'css/colors';

button.confirmButton {
  background-color: $teal;
  border: none;
}

.inviteLink {
  color: $gray4;
  text-decoration: underline;
  padding: 0 4px;

  &:hover {
    color: $gray4;
  }
}
