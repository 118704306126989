@import 'css/colors';
@import 'css/spacings';
@import 'src/js/design-system/Text/Text.module.scss';

.link {
  color: $platform-gray-700;
  text-decoration: underline;

  &:hover {
    color: $platform-gray-700;
  }
}

.subtitle {
  @include text-body-main;
  color: $platform-gray-700;
  display: flex;
  flex-direction: column;
  gap: $spacing-16;

  .modalTrigger {
    @include text-body-main-underline;
    color: $platform-gray-700;
  }
}
