@import 'css/spacings';

.signOutButton {
  margin-bottom: $spacing-8;
  margin-top: $spacing-8;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
