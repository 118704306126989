.formField {
  margin-bottom: 16px;
}

.formLabel label {
  width: 100%;
}

.formInput {
  flex: 1;

  input:not(:disabled),
  textarea:not(:disabled) {
    &:hover {
      border-color: #c4c4c4;
    }

    &:focus,
    &:active {
      border-color: #7d93ad;
    }
  }
}

.formText {
  display: block;
  white-space: pre-wrap;
}

.feedback {
  font-size: 0.625rem;
}
