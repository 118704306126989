$primary-gray-25: #f3f5fa;
$primary-gray-50: #e9ecf4;
$primary-gray-100: #e0e5ef;
$primary-gray-200: #dee4ea;
$primary-gray-300: #cbd4de;
$primary-gray-400: #b1bece;
$primary-gray-500: #97a9be;
$primary-gray-600: #7d93ad;
$primary-gray-700: #596d84;
$primary-gray-800: #3a4c62;
$primary-gray-900: #030e25;

$primary-green-500: #39b197;
$primary-green-600: #079d7d;
$primary-green-700: #077e65;
$primary-green-800: #056551;

$secondary-blue-300: #99ddfa;
$secondary-blue-500: #33bcf5;
$secondary-blue-600: #00abf2;
$secondary-blue-700: #0797d2;

$secondary-purple-300: #d1bff5;
$secondary-purple-400: #b99ff0;
$secondary-purple-600: #8b5fe6;
$secondary-purple-700: #612ecb;

$system-yellow-600: #ffc107;
$system-orange-500: #f19a37;
$system-orange-600: #fd7314;
$system-indigo-600: #6610f2;
$system-pink-600: #d63384;
$system-red-300: #e8959f;
$system-red-500: #eb4e3d;
$system-red-600: #dc3545;
$system-teal-500: #69adc6;
$system-teal-600: #14dea2;
$system-mint-500: #5ac4bd;
$system-cyan-600: #0dcaf0;
$system-green-500: #63ca56;
$system-success-600: #0cb413;

$performance-ketchup-600: #e86161;
$performance-ketchup-700: #ce3a3a;
$performance-ketchup-800: #af1d1d;
$performance-mustard-600: #ffb78e;
$performance-jewel-600: #64d5b3;

:export {
  primary-gray-25: $primary-gray-25;
  primary-gray-50: $primary-gray-50;
  primary-gray-100: $primary-gray-100;
  primary-gray-200: $primary-gray-200;
  primary-gray-300: $primary-gray-300;
  primary-gray-400: $primary-gray-400;
  primary-gray-500: $primary-gray-500;
  primary-gray-600: $primary-gray-600;
  primary-gray-700: $primary-gray-700;
  primary-gray-800: $primary-gray-800;
  primary-gray-900: $primary-gray-900;
  primary-green-500: $primary-green-500;
  primary-green-600: $primary-green-600;
  primary-green-700: $primary-green-700;
  primary-green-800: $primary-green-800;
  secondary-blue-300: $secondary-blue-300;
  secondary-blue-500: $secondary-blue-500;
  secondary-blue-600: $secondary-blue-600;
  secondary-blue-700: $secondary-blue-700;
  secondary-purple-300: $secondary-purple-300;
  secondary-purple-400: $secondary-purple-400;
  secondary-purple-600: $secondary-purple-600;
  secondary-purple-700: $secondary-purple-700;
  system-yellow-600: $system-yellow-600;
  system-orange-500: $system-orange-500;
  system-orange-600: $system-orange-600;
  system-indigo-600: $system-indigo-600;
  system-pink-600: $system-pink-600;
  system-red-500: $system-red-500;
  system-red-600: $system-red-600;
  system-teal-500: $system-teal-500;
  system-teal-600: $system-teal-600;
  system-mint-500: $system-mint-500;
  system-green-500: $system-green-500;
  system-cyan-600: $system-cyan-600;
  system-success-600: $system-success-600;
  performance-ketchup-600: $performance-ketchup-600;
  performance-ketchup-700: $performance-ketchup-700;
  performance-ketchup-800: $performance-ketchup-800;
  performance-mustard-600: $performance-mustard-600;
  performance-jewel-600: $performance-jewel-600;
}
