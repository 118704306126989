@import 'css/colors';
@import 'css/spacings';
@import 'css/site-variables';
@import 'src/js/design-system/Text/Text.module.scss';
@import 'js/design-system/Button/Button.module.scss';

.stickyHeader {
  display: flex;
  flex-direction: column;

  .header {
    padding: $spacing-8 $spacing-0;
    box-sizing: border-box;
    display: grid;
    row-gap: $spacing-4;
    grid-template-areas:
      'l r'
      'd d';

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .left {
      grid-area: l;
      gap: $spacing-8;

      .title {
        @include text-heading-h6;
        display: flex;
        align-items: center;
        gap: $spacing-16;
      }
    }

    .right {
      grid-area: r;
      flex: auto;
      justify-content: flex-end;
      gap: $spacing-12;

      .filterTriggerOpen {
        @include activeGhostBtn;
      }

      .divider {
        height: 32px;
        border-left: solid 1px $platform-gray-300;
      }
    }

    .description {
      grid-area: d;
      color: $platform-gray-700;
      max-width: 700px;
    }
  }

  .filters {
    display: flex;
    align-items: center;
    gap: $spacing-8;
    padding: 0 $spacing-8;
    background-color: $platform-gray-100;
    height: 0;
    overflow: hidden;
    transition: height $base-transition-time $base-transition-function;

    &.open {
      height: 50px;
      margin: $spacing-8 0;
      overflow: unset;
    }
  }
}
