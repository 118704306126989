@import 'css/spacings';
@import 'css/shadows';

.wrapper {
  max-width: 472px;
}

.header {
  padding-top: $spacing-16;
  padding-bottom: $spacing-16;
}

.content {
  position: relative;
  padding: $spacing-24 $spacing-24 0;
  max-height: 542px;
  overflow: auto;
}

.title {
  margin-top: $spacing-4;
  margin-right: $spacing-32;
}

.subtitle {
  margin-bottom: $spacing-4;
}

.closeButton {
  position: absolute;
  right: $spacing-16;
  top: $spacing-16;
}

.footerButton {
  flex-basis: 100%;
  justify-content: center;
}

.withShadow {
  box-shadow: $shadow-900;
}
