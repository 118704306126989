@import 'css/colors';
@import 'css/site-variables';

.numberInputField {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    flex: 1;
  }
}

.numberInputField :global(.react-numeric-input b) {
  display: none;
}
