@import 'css/spacings';
@import 'src/js/design-system/Form/Select/ListBox.module.scss';

.multiselectOption {
  @include listboxItem;
  padding: $spacing-14 $spacing-12;
  gap: $spacing-12;

  &.topAligned {
    align-items: flex-start;

    .iconContainer {
      margin-top: -2px;
    }
  }
}
