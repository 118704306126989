@import 'css/colors';
@import 'css/spacings';
@import 'css/site-variables';
@import 'js/design-system/Text/Text.module.scss';

.container {
  background-color: $yellow;
  color: $platform-gray-700;
  @include text-body-main-bold;

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-8;
    height: $banner-height;

    .upgradeLink {
      @include text-body-main-bold;
      color: currentColor !important;
      text-decoration: underline;
    }
  }
}
