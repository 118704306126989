@import 'css/colors';
@import 'css/spacings';

.campaign {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: $spacing-64;
  padding: 0 $spacing-64 $spacing-64;
  box-sizing: border-box;
  background-color: $primary-gray-25;

  .illustration {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 300px;
  }

  .heading {
    text-align: center;
  }

  .subText {
    padding-top: $spacing-4;
  }

  .body {
    flex: auto;
    display: flex;
    flex-direction: column;
    gap: $spacing-32;
    max-width: 750px;
    align-self: center;

    .listItem {
      display: flex;
      gap: $spacing-16;
    }
  }
}

@media (max-width: 1400px) {
  .heading.short {
    max-width: 340px;
    align-self: center;
  }
}

@media (max-width: 1200px) {
  .campaign {
    padding-top: $spacing-72;

    .illustration {
      display: none;
    }
  }
}

@media (max-width: 1080px) {
  .campaign {
    display: none;

    .heading.short {
      max-width: unset;
      align-self: start;
    }
  }
}
