@import 'css/colors';
@import 'css/site-variables';
@import 'css/spacings';

.employeeVerify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  max-height: 0px;
  animation: onLoadAnimation 0.3s ease;
  animation-fill-mode: forwards;

  .text {
    display: flex;
    align-items: center;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.toVerify {
  background-color: $oc-primary;
}

.confirmed {
  transform: translateY(0px);
  max-height: 48px;
  background-color: $green-success;
  animation: endBackgroundAnimation 0.3s linear 3s;
  animation-fill-mode: forwards;
}

.endTextAnimation {
  animation: endTextAnimate 0.5s ease;
  opacity: 1;
}

.endBackgroundAnimation {
  animation: endBackgroundAnimate 1s ease 3s;
  animation-fill-mode: forwards;
}

.icon {
  margin-right: $spacing-4;
}

@keyframes onLoadAnimation {
  0% {
    transform: translateY(-48px);
  }
  100% {
    transform: translateY(0px);
    max-height: 48px;
  }
}

@keyframes startTextAnimate {
  0% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

@keyframes endTextAnimate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes startBackgroundAnimate {
  from {
    background-color: $oc-primary;
  }
  to {
    background-color: $green-success;
  }
}

@keyframes endBackgroundAnimate {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-48px);
    max-height: 0px;
    display: none;
  }
}
