@import 'css/colors';
@import 'css/site-variables';
@import 'css/spacings';
@import 'js/design-system/Form/Select/ListBox.module.scss';
@import 'js/design-system/Text/Text.module.scss';

.menu {
  top: 100%;
  right: 0;
  width: $user-menu-width;

  .adminConsole {
    margin-top: $spacing-8;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    padding: $spacing-14 $spacing-12;
    margin-top: -$spacing-8;

    &.team {
      background-color: $subscription-team-secondary;
    }

    &.premium,
    &.business,
    &.growth {
      background-color: $subscription-premium-secondary;
    }

    &.premiumPlus,
    &.professional,
    &.scale {
      background-color: $subscription-professional-secondary;
    }
  }

  .subscription {
    justify-content: space-between;
    pointer-events: none;

    .planName {
      @include text-misc-input-bold;
    }

    &.team {
      background-color: $subscription-team-primary;
      color: $neutral-full-white;
    }

    &.premium,
    &.business,
    &.growth {
      background-color: $subscription-premium-primary;
    }

    &.premiumPlus,
    &.professional,
    &.scale {
      background-color: $subscription-professional-primary;
      color: $neutral-full-white;
    }
  }
}
