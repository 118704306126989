@import 'css/colors';
@import 'css/spacings';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
  padding: $spacing-16 0;
  overflow-y: auto;

  .row {
    color: $platform-gray-700;
    display: flex;

    > div {
      flex: 1;

      .bold {
        color: $platform-gray-900;
        font-weight: 700;
      }
    }
  }

  .divider {
    border-top: solid 1px $platform-gray-100;
  }
}
