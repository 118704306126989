@import 'css/colors';
@import 'css/spacings';
@import 'src/js/design-system/Text/Text.module.scss';

.employeeCount {
  display: flex;
  align-items: center;
  gap: $spacing-4;
  color: $platform-gray-500;
  @include text-badge-large;
}
