@import 'css/border-radius';
@import 'css/colors';
@import 'css/spacings';
@import 'css/site-variables';
@import 'js/design-system/Button/Button.module.scss';
@import 'js/design-system/Text/Text.module.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;

  .resultsCard {
    display: flex;
    flex-direction: column;
    border: solid 1px $platform-gray-300;
    border-radius: $border-radius-4;
    padding: $spacing-16 $spacing-16 0 $spacing-16;

    .topRow {
      display: flex;
      align-items: center;
      gap: $spacing-8;

      .toggle {
        margin-left: auto;
      }

      .active,
      .active:hover {
        @include activeGhostBtn;
      }
    }

    .copyText {
      @include text-misc-help;
      color: $platform-gray-700;
      margin-top: $spacing-8;
      padding-bottom: $spacing-16;
    }

    .divider {
      margin: 0 -#{$spacing-16} $spacing-12;
      border-top: solid 1px $platform-gray-200;
    }

    .breakdownContainer {
      margin-top: $spacing-12;
      height: 0px;
      overflow: hidden;
      transition: height $base-transition-time;

      &.open {
        height: 224px;
        border-top: solid 1px $platform-gray-100;
      }
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  color: $platform-gray-700;
  max-height: 384px;

  .employee {
    margin-top: $spacing-24;
    display: flex;
    gap: $spacing-12;

    > *:last-child {
      margin-left: auto;
      align-self: flex-end;
    }

    .employeeData {
      display: flex;
      flex-direction: column;

      .salary {
        flex: 1;
        display: flex;
        gap: $spacing-8;
      }
    }
  }

  .divider {
    border-top: solid 1px $platform-gray-100;
    margin-top: $spacing-32;
    margin-bottom: $spacing-16;
  }

  > *:last-child {
    padding-bottom: $spacing-24;
  }
}
