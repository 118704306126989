@import 'css/spacings';
@import 'src/js/design-system/Form/Select/ListBox.module.scss';

.menuContainer {
  position: relative;
  max-width: fit-content;
}

.menu {
  @include listbox;
  min-width: 14rem;

  &.filterable {
    min-width: 300px;

    &:not(.hasNested) .itemsContainer {
      max-height: $filterable-menu-max-height;
    }
  }

  &.nested {
    position: absolute;
    top: 0;
  }

  .item {
    @include listboxItem;

    &:hover > .nested {
      display: flex;
      flex-direction: column;
    }
  }

  .nestedIcon {
    margin-left: auto;
  }

  .filter {
    padding: $spacing-8 $spacing-12 $spacing-16;
    top: 0;
  }

  .sticky {
    position: sticky;
    background-color: $neutral-full-white;
    z-index: 1;
  }

  .actionButton {
    padding: $spacing-4 $spacing-12;

    > button {
      width: 100%;
    }
  }
}
