$platform-blue-100: hsla(223, 98%, 98%, 1);
$platform-blue-200: hsla(223, 95%, 95%, 1);
$platform-blue-300: hsla(223, 92%, 88%, 1);
$platform-blue-400: hsla(223, 86%, 77%, 1);
$platform-blue-500: hsla(223, 90%, 62%, 1);
$platform-blue-600: hsla(223, 66%, 47%, 1);
$platform-blue-700: hsla(223, 70%, 34%, 1);
$platform-blue-800: hsla(223, 75%, 21%, 1);
$platform-blue-900: hsla(223, 96%, 11%, 1);

$platform-gray-100: hsla(223, 53%, 97%, 1);
$platform-gray-200: hsla(223, 35%, 94%, 1);
$platform-gray-300: hsla(223, 24%, 87%, 1);
$platform-gray-400: hsla(223, 18%, 76%, 1);
$platform-gray-500: hsla(223, 12%, 58%, 1);
$platform-gray-600: hsla(222, 11%, 43%, 1);
$platform-gray-700: hsla(223, 15%, 28%, 1);
$platform-gray-800: hsla(221, 20%, 16%, 1);
$platform-gray-900: hsla(220, 40%, 7%, 1);

$red-100: hsla(4, 100%, 98%, 1);
$red-200: hsla(5, 96%, 95%, 1);
$red-300: hsla(5, 96%, 90%, 1);
$red-400: hsla(6, 80%, 80%, 1);
$red-500: hsla(6, 81%, 58%, 1);
$red-600: hsla(6, 65%, 36%, 1);
$red-700: hsla(5, 90%, 21%, 1);
$red-800: hsla(5, 95%, 15%, 1);
$red-900: hsla(4, 95%, 6%, 1);

$orange-100: hsla(38, 100%, 97%, 1);
$orange-200: hsla(36, 95%, 92%, 1);
$orange-300: hsla(35, 95%, 85%, 1);
$orange-400: hsla(32, 83%, 72%, 1);
$orange-500: hsla(32, 87%, 58%, 1);
$orange-600: hsla(32, 75%, 36%, 1);
$orange-700: hsla(34, 85%, 24%, 1);
$orange-800: hsla(35, 90%, 15%, 1);
$orange-900: hsla(36, 95%, 10%, 1);

$yellow-100: hsla(56, 95%, 96%, 1);
$yellow-200: hsla(53, 94%, 89%, 1);
$yellow-300: hsla(50, 90%, 80%, 1);
$yellow-400: hsla(46, 92%, 75%, 1);
$yellow-500: hsla(46, 92%, 62%, 1);
$yellow-600: hsla(44, 80%, 43%, 1);
$yellow-700: hsla(42, 75%, 33%, 1);
$yellow-800: hsla(42, 80%, 19%, 1);
$yellow-900: hsla(42, 95%, 11%, 1);

$green-100: hsla(113, 100%, 97%, 1);
$green-200: hsla(114, 87%, 93%, 1);
$green-300: hsla(113, 73%, 80%, 1);
$green-400: hsla(113, 60%, 71%, 1);
$green-500: hsla(113, 52%, 56%, 1);
$green-600: hsla(113, 55%, 35%, 1);
$green-700: hsla(113, 58%, 24%, 1);
$green-800: hsla(113, 96%, 14%, 1);
$green-900: hsla(113, 95%, 8%, 1);

$mint-100: hsla(176, 98%, 96%, 1);
$mint-200: hsla(175, 85%, 92%, 1);
$mint-300: hsla(176, 65%, 84%, 1);
$mint-400: hsla(176, 60%, 70%, 1);
$mint-500: hsla(176, 47%, 56%, 1);
$mint-600: hsla(176, 40%, 35%, 1);
$mint-700: hsla(176, 45%, 26%, 1);
$mint-800: hsla(176, 94%, 14%, 1);
$mint-900: hsla(176, 98%, 9%, 1);

$teal-100: hsla(196, 100%, 98%, 1);
$teal-200: hsla(195, 85%, 94%, 1);
$teal-300: hsla(196, 67%, 85%, 1);
$teal-400: hsla(196, 52%, 73%, 1);
$teal-500: hsla(196, 45%, 59%, 1);
$teal-600: hsla(196, 45%, 41%, 1);
$teal-700: hsla(196, 64%, 26%, 1);
$teal-800: hsla(196, 85%, 15%, 1);
$teal-900: hsla(196, 95%, 8%, 1);

$cyan-100: hsla(203, 99%, 98%, 1);
$cyan-200: hsla(203, 94%, 95%, 1);
$cyan-300: hsla(203, 85%, 88%, 1);
$cyan-400: hsla(203, 78%, 78%, 1);
$cyan-500: hsla(203, 75%, 68%, 1);
$cyan-600: hsla(203, 52%, 50%, 1);
$cyan-700: hsla(203, 78%, 35%, 1);
$cyan-800: hsla(204, 90%, 20%, 1);
$cyan-900: hsla(203, 98%, 11%, 1);

$indigo-100: hsla(241, 98%, 98%, 1);
$indigo-200: hsla(241, 85%, 93%, 1);
$indigo-300: hsla(241, 68%, 80%, 1);
$indigo-400: hsla(241, 58%, 67%, 1);
$indigo-500: hsla(241, 56%, 57%, 1);
$indigo-600: hsla(241, 50%, 46%, 1);
$indigo-700: hsla(241, 55%, 34%, 1);
$indigo-800: hsla(241, 72%, 24%, 1);
$indigo-900: hsla(241, 98%, 10%, 1);

$purple-100: hsla(276, 99%, 98%, 1);
$purple-200: hsla(276, 87%, 93%, 1);
$purple-300: hsla(276, 68%, 83%, 1);
$purple-400: hsla(276, 60%, 70%, 1);
$purple-500: hsla(276, 62%, 59%, 1);
$purple-600: hsla(275, 54%, 40%, 1);
$purple-700: hsla(276, 62%, 30%, 1);
$purple-800: hsla(276, 92%, 19%, 1);
$purple-900: hsla(276, 98%, 10%, 1);

$pink-100: hsla(350, 100%, 97%, 1);
$pink-200: hsla(350, 90%, 93%, 1);
$pink-300: hsla(351, 83%, 85%, 1);
$pink-400: hsla(352, 77%, 72%, 1);
$pink-500: hsla(352, 81%, 59%, 1);
$pink-600: hsla(350, 75%, 34%, 1);
$pink-700: hsla(348, 85%, 24%, 1);
$pink-800: hsla(344, 97%, 17%, 1);
$pink-900: hsla(342, 98%, 12%, 1);

$brown-100: hsla(35, 98%, 97%, 1);
$brown-200: hsla(36, 95%, 93%, 1);
$brown-300: hsla(36, 56%, 78%, 1);
$brown-400: hsla(36, 49%, 65%, 1);
$brown-500: hsla(35, 30%, 50%, 1);
$brown-600: hsla(35, 38%, 36%, 1);
$brown-700: hsla(36, 70%, 19%, 1);
$brown-800: hsla(36, 94%, 13%, 1);
$brown-900: hsla(35, 95%, 8%, 1);

$gray-100: hsla(0, 0%, 97%, 1);
$gray-200: hsla(0, 0%, 89%, 1);
$gray-300: hsla(0, 0%, 80%, 1);
$gray-400: hsla(0, 0%, 68%, 1);
$gray-500: hsla(0, 0%, 57%, 1);
$gray-600: hsla(0, 0%, 43%, 1);
$gray-700: hsla(0, 0%, 35%, 1);
$gray-800: hsla(0, 0%, 20%, 1);
$gray-900: hsla(0, 0%, 10%, 1);

$neutral-full-white: hsla(0, 0%, 100%, 1);
$neutral-full-black: hsla(0, 0%, 0%, 1);

:export {
  platform-blue-100: $platform-blue-100;
  platform-blue-200: $platform-blue-200;
  platform-blue-300: $platform-blue-300;
  platform-blue-400: $platform-blue-400;
  platform-blue-500: $platform-blue-500;
  platform-blue-600: $platform-blue-600;
  platform-blue-700: $platform-blue-700;
  platform-blue-800: $platform-blue-800;
  platform-blue-900: $platform-blue-900;

  platform-gray-100: $platform-gray-100;
  platform-gray-200: $platform-gray-200;
  platform-gray-300: $platform-gray-300;
  platform-gray-400: $platform-gray-400;
  platform-gray-500: $platform-gray-500;
  platform-gray-600: $platform-gray-600;
  platform-gray-700: $platform-gray-700;
  platform-gray-800: $platform-gray-800;
  platform-gray-900: $platform-gray-900;

  red-100: $red-100;
  red-200: $red-200;
  red-300: $red-300;
  red-400: $red-400;
  red-500: $red-500;
  red-600: $red-600;
  red-700: $red-700;
  red-800: $red-800;
  red-900: $red-900;

  orange-100: $orange-100;
  orange-200: $orange-200;
  orange-300: $orange-300;
  orange-400: $orange-400;
  orange-500: $orange-500;
  orange-600: $orange-600;
  orange-700: $orange-700;
  orange-800: $orange-800;
  orange-900: $orange-900;

  yellow-100: $yellow-100;
  yellow-200: $yellow-200;
  yellow-300: $yellow-300;
  yellow-400: $yellow-400;
  yellow-500: $yellow-500;
  yellow-600: $yellow-600;
  yellow-700: $yellow-700;
  yellow-800: $yellow-800;
  yellow-900: $yellow-900;

  green-100: $green-100;
  green-200: $green-200;
  green-300: $green-300;
  green-400: $green-400;
  green-500: $green-500;
  green-600: $green-600;
  green-700: $green-700;
  green-800: $green-800;
  green-900: $green-900;

  mint-100: $mint-100;
  mint-200: $mint-200;
  mint-300: $mint-300;
  mint-400: $mint-400;
  mint-500: $mint-500;
  mint-600: $mint-600;
  mint-700: $mint-700;
  mint-800: $mint-800;
  mint-900: $mint-900;

  teal-100: $teal-100;
  teal-200: $teal-200;
  teal-300: $teal-300;
  teal-400: $teal-400;
  teal-500: $teal-500;
  teal-600: $teal-600;
  teal-700: $teal-700;
  teal-800: $teal-800;
  teal-900: $teal-900;

  cyan-100: $cyan-100;
  cyan-200: $cyan-200;
  cyan-300: $cyan-300;
  cyan-400: $cyan-400;
  cyan-500: $cyan-500;
  cyan-600: $cyan-600;
  cyan-700: $cyan-700;
  cyan-800: $cyan-800;
  cyan-900: $cyan-900;

  indigo-100: $indigo-100;
  indigo-200: $indigo-200;
  indigo-300: $indigo-300;
  indigo-400: $indigo-400;
  indigo-500: $indigo-500;
  indigo-600: $indigo-600;
  indigo-700: $indigo-700;
  indigo-800: $indigo-800;
  indigo-900: $indigo-900;

  purple-100: $purple-100;
  purple-200: $purple-200;
  purple-300: $purple-300;
  purple-400: $purple-400;
  purple-500: $purple-500;
  purple-600: $purple-600;
  purple-700: $purple-700;
  purple-800: $purple-800;
  purple-900: $purple-900;

  pink-100: $pink-100;
  pink-200: $pink-200;
  pink-300: $pink-300;
  pink-400: $pink-400;
  pink-500: $pink-500;
  pink-600: $pink-600;
  pink-700: $pink-700;
  pink-800: $pink-800;
  pink-900: $pink-900;

  brown-100: $brown-100;
  brown-200: $brown-200;
  brown-300: $brown-300;
  brown-400: $brown-400;
  brown-500: $brown-500;
  brown-600: $brown-600;
  brown-700: $brown-700;
  brown-800: $brown-800;
  brown-900: $brown-900;

  gray-100: $gray-100;
  gray-200: $gray-200;
  gray-300: $gray-300;
  gray-400: $gray-400;
  gray-500: $gray-500;
  gray-600: $gray-600;
  gray-700: $gray-700;
  gray-800: $gray-800;
  gray-900: $gray-900;

  neutral-full-white: $neutral-full-white;
  neutral-full-black: $neutral-full-black;
}
