@import 'css/colors';
@import 'css/spacings';

.header {
  padding-top: $spacing-48;
  color: $platform-gray-700;
  font-size: 40px;
  line-height: 47px;
  font-weight: bold;
}

h1 {
  font-size: 1.875rem;
}

.content {
  padding-top: $spacing-32;
}
