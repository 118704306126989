@import 'css/colors';
@import 'css/border-radius';
@import 'css/shadows';
@import 'css/spacings';
@import 'css/site-variables';
@import 'src/js/design-system/Text/Text.module.scss';

$cell-size: 48px;

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.levels {
  display: inline-flex;
  height: $cell-size;
  justify-content: space-between;
  box-sizing: content-box;
  margin-bottom: $spacing-8;

  .level {
    flex: 1;
    @include text-misc-input;
    height: $cell-size;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $neutral-full-white;
    border: solid 1px $platform-gray-300;
    border-right: none;

    &:first-of-type {
      border-top-left-radius: $border-radius-4;
      border-bottom-left-radius: $border-radius-4;
    }

    &:last-of-type {
      border-right: solid 1px $platform-gray-300;
      border-top-right-radius: $border-radius-4;
      border-bottom-right-radius: $border-radius-4;
    }

    &:hover:not(&.disabled):not(&.readonly):not(&.selected) {
      box-shadow: inset 0 0 0 1px $platform-gray-500;
    }

    &.selected {
      @include text-misc-input-bold;
      background-color: $platform-gray-300;
    }

    &.disabled {
      pointer-events: none;
      color: $platform-gray-300;
    }

    &.readonly {
      cursor: help;
      color: $platform-gray-400;

      &:hover {
        box-shadow: inset 0 0 0 1px $platform-gray-400;
      }
    }
  }
}

.helpText {
  @include text-misc-help;
  margin-bottom: $spacing-4;
  color: $platform-gray-900;
  word-break: break-word;
}
