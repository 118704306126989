.inputAppend {
  position: absolute;
  top: 16px;
  right: 12px;
  transform: translateY(-50%);

  > * {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
