@import 'css/colors';
@import 'css/spacings';
@import 'js/design-system/Button/Button.module.scss';
@import 'js/design-system/Text/Text.module.scss';

.table {
  margin-top: $spacing-16;
}

.headerRow {
  padding: $spacing-4 $spacing-8;

  > *:not(:first-child) {
    text-align: right !important;
  }
}

.row {
  padding: $spacing-4 $spacing-8 !important;

  &.noEquity {
    padding: $spacing-8 !important;
  }

  &:nth-child(even) {
    background-color: $platform-gray-100;
  }
}

.cell {
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
  align-items: flex-end;

  .equity {
    @include text-misc-help;
    color: $platform-gray-700;
  }
}

.equityToggleContainer {
  margin: auto;
}

.equityToggle {
  @include text-misc-caption;
}

.active,
.active:hover {
  @include activeGhostBtn;
}
