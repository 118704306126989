@import 'css/spacings';

.fieldWrapper {
  flex: 1;
  width: 100%;

  .rawInput {
    display: none;
  }

  .uploadBtn {
    width: 100%;
    flex: 1 1;
  }
}

.fieldLabel {
  display: flex;
  margin-top: 0;
}

.fileBox {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  flex: 3 1;
}

.fileBox[disabled] {
  background-color: #e9ecef;
}

.errorContainer {
  padding: 9px 12px;
  border: 2px solid #fe7572;
  border-radius: 3px;
  color: #f00;
}

.errorLabel {
  font-weight: bold;
  padding-bottom: 16px;
}

.error {
  white-space: pre-wrap;
}
