@use 'sass:map';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin responsive($class) {
  @if $class == xxs {
    @media (max-width: 680px) {
      @content;
    }
  } @else if $class == xs {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $class == xssm {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  } @else if $class == sml {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $class == mlg {
    @media (min-width: 1200px) and (max-width: 1400px) {
      @content;
    }
  } @else {
    @media (min-width: $class) {
      @content;
    }
  }
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - 0.02, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
