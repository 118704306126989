@import 'css/colors';
@import 'css/spacings';
@import 'css/site-variables';

.selectWrapper {
  padding: $spacing-10;
}

.select {
  width: $dropdown-in-text-field-width;
  margin: 0 (-$spacing-10) 0 $spacing-8;

  input {
    box-shadow: none !important;
  }

  &:before {
    content: '';
    width: 1px;
    height: 24px;
    background-color: $primary-gray-50;
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
  }
}
