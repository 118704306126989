@import 'css/colors';
@import 'css/spacings';

.switchInput {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1;
  color: $platform-gray-900;

  :global(.form-switch) {
    margin: 0 0.5rem;
    min-height: calc(1.6em + 0.5rem + 2px);
    display: flex;
    align-items: center;

    :global(.form-check-input) {
      width: 3rem;
      height: 1.5rem;
      margin-top: 0;
    }
  }

  :global(.form-text) {
    width: 100%;
  }

  > :global(small.form-text) {
    margin-left: 0.5rem;
    line-height: 1.5;
  }
}

.checkboxGroup {
  margin-bottom: $spacing-8;

  &:last-child {
    margin-bottom: 0;
  }
}
