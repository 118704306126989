$line-height-104: 104px;
$line-height-72: 72px;
$line-height-56: 56px;
$line-height-40: 40px;
$line-height-32: 32px;
$line-height-24: 24px;
$line-height-20: 20px;
$line-height-18: 18px;
$line-height-16: 16px;
$line-height-15: 15px;
$line-height-8: 8px;
