.toggle {
  background-color: transparent;
  border: none;
  padding: 0 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
}

/* HACK: Hard-coded to bootstraps input form-control height */
.sm {
  min-height: calc(1.6em + 0.5rem);
  font-size: 0.76563rem;
}

.md {
  min-height: calc(1.6em + 0.725rem);
  font-size: 0.825rem;
}

.lg {
  min-height: calc(1.6em + 1rem);
  font-size: 1rem;
}
