@import 'palette';
@import 'old-palette';
@import 'bs-variables';

$oc-brand-primary: #42ccb0;
$oc-primary: $platform-blue-500;
$oc-primary-hover: $platform-blue-600;
$oc-primary-active: $platform-blue-700;
$oc-primary-disabled: $platform-blue-300;
$oc-secondary: $platform-blue-200;

$text-main: $platform-gray-900;
$text-light: $platform-gray-700;

$logo-light: $neutral-full-white;
$logo-light-secondary: rgba($logo-light, 0.5);
$logo-dark: $neutral-full-black;
$logo-dark-secondary: rgba($logo-dark, 0.5);

$upgrade: $yellow-500;
$upgrade-active: $yellow-600;

$performance-lowest: $red-500;
$performance-low: $orange-500;
$performance-mid: $green-500;
$performance-high: $cyan-500;
$performance-highest: $purple-500;

$subscription-team-primary: $green-500;
$subscription-team-secondary: $green-200;
$subscription-premium-primary: $cyan-500;
$subscription-premium-secondary: $cyan-200;
$subscription-professional-primary: $purple-500;
$subscription-professional-secondary: $purple-200;

$teaserOverlay: rgba($neutral-full-white, 0.5);

$misc-snackbar-bg: #262626;
$shadow-drop-shadow: $platform-blue-900;

:export {
  oc-brand-primary: $oc-brand-primary;
  oc-primary: $oc-primary;
  oc-secondary: $oc-secondary;
  text-main: $platform-gray-900;
  text-light: $platform-gray-700;
  logo-light: $logo-light;
  logo-light-secondary: $logo-light-secondary;
  logo-dark: $logo-dark;
  logo-dark-secondary: $logo-dark-secondary;
  upgrade: $upgrade;
  performance-lowest: $performance-lowest;
  performance-low: $performance-low;
  performance-mid: $performance-mid;
  performance-high: $performance-high;
  performance-highest: $performance-highest;
  subscription-team-primary: $subscription-team-primary;
  subscription-team-secondary: $subscription-team-secondary;
  subscription-premium-primary: $subscription-premium-primary;
  subscription-premium-secondary: $subscription-premium-secondary;
  subscription-professional-primary: $subscription-professional-primary;
  subscription-professional-secondary: $subscription-professional-secondary;
  teaserOverlay: $teaserOverlay;
  misc-snackbar-bg: $misc-snackbar-bg;
  shadow-drop-shadow: $shadow-drop-shadow;
}

// OLD
$green-success: #46c327;
$darkgrey: #555;
$light-blue-2: #ecf9ff;
$white-filter: invert(85%) sepia(100%) saturate(1%) hue-rotate(10deg) brightness(106%)
  contrast(101%);
$employee-disabled-color: $platform-gray-700;
$navbar-dark-brand-color: $gray2;
$employee-need-upgrade-color: $platform-gray-500;
$popular-searches-container-background-color: #fcfdfe; // This is the resolved HEX from $platform-gray-100 with 25% opacity over white background.
