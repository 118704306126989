@import 'css/border-radius';
@import 'css/colors';
@import 'css/spacings';

.colorPill {
  border-radius: $border-radius-32;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: $spacing-32;
  padding: $spacing-2 $spacing-8;
  height: 20px;
  flex-shrink: 0;
  gap: $spacing-4;

  &.withLeftElement {
    padding-left: $spacing-4;
  }

  &.withRightElement {
    padding-right: $spacing-4;
  }

  &.blue {
    background-color: $oc-secondary;
    color: $platform-blue-600;
  }
  &.green {
    background-color: $green-300;
    color: $green-800;
  }
  &.red {
    background-color: $red-300;
    color: $red-700;
  }
  &.mint {
    background-color: $mint-300;
    color: $mint-800;
  }
  &.orange {
    background-color: $orange-300;
    color: $orange-800;
  }
  &.cyan {
    background-color: $cyan-300;
    color: $cyan-800;
  }
  &.indigo {
    background-color: $indigo-200;
    color: $indigo-700;
  }
  &.purple {
    background-color: $purple-200;
    color: $purple-700;
  }
  &.yellow {
    background-color: $yellow-300;
    color: $yellow-800;
  }
  &.brown {
    background-color: $brown-300;
    color: $brown-700;
  }
  &.gray {
    background-color: $gray-200;
    color: $gray-800;
  }
}
