@import 'css/border-radius';
@import 'css/colors';
@import 'css/shadows';
@import 'css/site-variables';
@import 'css/spacings';
@import 'js/design-system/Text/Text.module.scss';

.tooltip {
  @include text-misc-input;
  z-index: $z-index-max;
  padding: $spacing-4 $spacing-8;
  border-radius: $border-radius-4;
  border: solid 1px $platform-gray-600;
  width: max-content;
  color: $neutral-full-white;
  background-color: $neutral-full-black;
  text-align: center;
  max-width: $tooltip-width;

  &.popover {
    color: $neutral-full-black;
    background-color: $neutral-full-white;
    border-color: $platform-gray-300;
    box-shadow: $shadow-300;
    text-align: unset;
    max-width: fit-content;

    .closeButton {
      width: 100%;
      margin-top: $spacing-16;
      margin-bottom: $spacing-12;
    }
  }
}
