@import 'css/colors';
@import 'css/spacings';

$bottom-border-size: $spacing-4;
$bottom-padding: $spacing-14;

.tab {
  display: flex;
  padding-bottom: $bottom-border-size + $bottom-padding;

  &:not(.active):hover {
    border-bottom: $bottom-border-size solid $platform-gray-500;
    padding-bottom: $bottom-padding;
  }
}

.active {
  border-bottom: $spacing-4 solid $oc-primary;
  padding-bottom: $bottom-padding;
}
