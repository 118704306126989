@import 'css/colors';
@import 'css/site-variables';
@import 'css/spacings';
@import 'js/design-system/Text/Text.module.scss';

// Special case for a nested menu.
// Because the trigger iteslf is a menu item, we need to let the nested menu container fill the parent div (a listbox item).
.menuContainer {
  max-width: unset !important;
}

.triggerTextContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  .triggerSubtext {
    @include text-misc-help;
    color: $platform-gray-600;
  }
}
