@import 'css/colors';
@import 'css/spacings';
@import 'css/mixins';
@import 'css/site-variables';
@import 'src/js/design-system/Text/Text.module.scss';

.tableContent {
  overflow: auto;
  max-height: calc(100vh - 352px - $navbar-height);
}

.rowGroup,
.headerRowGroup {
  min-width: 1080px;
}

.row {
  padding: 0 !important;
  border-color: $primary-gray-200 !important;
  background-color: $neutral-full-white;
  height: 49px;

  &:last-child {
    height: 48px;
  }
}

.rowGroup:last-child .row:last-child {
  height: 49px;
}

.headerRowGroup {
  position: sticky;
  top: 0;
  z-index: 30;

  div[role='row'] {
    background-color: $neutral-full-white;
  }
}

.tableLegend {
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: $spacing-16;

  .verifyCircle {
    margin-right: $spacing-4;
  }

  .infoIcon {
    margin-left: $spacing-4;
  }
}

.tableHeader {
  position: sticky;
  z-index: 20;
}

.employeeCellWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-12;

  .employeeCell {
    max-width: calc(100% - 20px);
  }

  .employeeCellText {
    @include ellipsis;
  }
}

.deptPercentile,
.employeePercentileWrapper,
.percentile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.employeePercentileWrapper {
  gap: $spacing-6;
  flex-wrap: wrap;
}

.deptPercentile {
  gap: $spacing-8;
}

.locationMainText {
  font-weight: 500;
}

.locationSubText {
  margin-left: $spacing-4;
}

.verifyCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $oc-primary;
  flex-shrink: 0;

  &.hidden {
    visibility: hidden;
  }

  &.inputPrepend {
    margin-right: $spacing-4;
  }
}

.loadingCellEmployee,
.loadingCellDepartment {
  width: 50%;
}

.bottomMargin {
  margin-bottom: $spacing-96;
}

.levelMenu {
  min-width: 300px;
}

.tabContent {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.checkbox {
  position: sticky;
  left: 0;
}

.rowCheckbox,
.name {
  position: sticky;
  background-color: inherit;
  z-index: 10;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rowCheckbox {
  padding-left: $spacing-16;
  left: 0;
}

.name {
  min-width: 272px;
  max-width: 272px;
  position: sticky;
  left: 52px;
}

.percentile {
  min-width: 172px;
  max-width: 172px;
}

.level {
  min-width: 120px;
  max-width: 120px;
}

.contextMenu {
  min-width: 96px;
  max-width: 96px;
}

.jobRole,
.level,
.location {
  padding-right: 0 !important;

  input {
    border-left: 1px solid $primary-gray-200;
    border-radius: 0;
    background-color: transparent !important;
  }

  input:not(:hover):not(:focus) {
    box-shadow: none !important;
  }
}

.location {
  input {
    border-right: 1px solid $primary-gray-200;
  }
}
