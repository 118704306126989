@import 'css/colors';
@import 'css/border-radius';
@import 'css/spacings';
@import 'css/site-variables';
@import 'css/mixins';

.button {
  display: flex;
  border: none;
  border-radius: $border-radius-4;
  align-items: center;
  justify-content: center;
  transition: background-color $base-transition-time;
  color: $platform-gray-700;

  &.default {
    background-color: transparent;
  }

  &.gray {
    background-color: $platform-gray-100;
  }

  &:hover:not(:disabled) {
    background-color: $platform-gray-200;
    color: $platform-gray-900 !important;
  }

  &:active:not(:disabled) {
    background-color: $platform-gray-300;
    color: $platform-gray-900 !important;
  }

  &:disabled {
    cursor: not-allowed;
    color: $platform-gray-500;
    background-color: transparent;
  }
}

.small {
  padding: $spacing-6;
}
.medium {
  padding: $spacing-8;
}
.large {
  padding: $spacing-12;
}

.circle {
  border-radius: $border-radius-circle;
}
