@import 'css/spacings';
@import 'css/colors';

.actionableModal {
  display: flex;
  flex-direction: column;
  max-width: 472px;
  max-height: 680px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-16 $spacing-16 $spacing-16 $spacing-24;
  border-bottom: 1px solid $platform-gray-100;
}

.content {
  display: flex;
  flex-direction: column;
  padding: $spacing-24;
  gap: $spacing-24;
  overflow-y: auto;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-16;
  padding: $spacing-16 $spacing-24;
  border-top: 1px solid $platform-gray-100;

  > * {
    width: 100%;
  }
}
