@import 'css/colors';
@import 'css/spacings';
@import 'src/js/design-system/Text/Text.module.scss';

.labelWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $spacing-8;
}

.label {
  @include text-subheading-small;

  color: $platform-gray-900;
  font-weight: 500;
}

.asterisk {
  @include text-subheading-small;
  color: $oc-primary;
}

.subLabel {
  color: $platform-gray-500;

  > a {
    @include text-body-main-underline;
    color: $platform-gray-500;
  }
}
