@import 'css/spacings';
@import 'css/colors';
@import 'css/border-radius';
@import 'css/site-variables';
@import 'src/js/design-system/Form/TextField/TextFieldContainer.module.scss';

.container {
  display: flex;
  position: relative;
  column-gap: $spacing-8;
  flex: 1;

  &.expanded {
    .button {
      display: block;
    }
  }

  &.disabled {
    .area {
      @include disabled;
      transition: none;
    }
  }
}

.avatar {
  margin-left: -$spacing-4;
}

.field {
  padding: $spacing-8 $spacing-12;
  box-shadow: none !important;
  transition: $base-transition-time;
  transition-property: height, min-height;

  &::placeholder {
    opacity: 1;
    color: $platform-gray-700;
  }
}

.area {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0 0 0 1px $primary-gray-400;
  border-radius: $border-radius-4;
  overflow: hidden;
  transition: box-shadow $base-transition-time;
  background-color: $neutral-full-white;
  position: relative;

  &:hover {
    box-shadow: 0 0 0 1.25px $primary-gray-500;
  }

  &:focus-within {
    box-shadow: 0 0 0 1.5px $primary-gray-600;
  }

  .button {
    display: none;
    align-self: flex-end;
    margin: $spacing-8;
  }
}
