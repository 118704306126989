@import 'css/colors';
@import 'css/border-radius';
@import 'css/spacings';
@import 'css/site-variables';
@import 'src/js/design-system/Text/Text.module.scss';

@mixin withNotStates {
  &:not(&--disabled):not(&--readonly):not(&--error) {
    @content;
  }
}

@mixin active {
  &:hover {
    box-shadow: inset 0 0 0 1.25px $primary-gray-500;
  }

  &:active,
  &:focus {
    box-shadow: inset 0 0 0 1.5px $primary-gray-600;
  }
}

@mixin default {
  @include text-misc-input;
  width: 100%;
  background-color: $neutral-full-white;
  box-shadow: inset 0 0 0 1px $primary-gray-200;
  transition: box-shadow $base-transition-time $base-transition-function;
  border-radius: $border-radius-4;
  outline: 0;
  line-height: $spacing-16;
  color: $neutral-full-black;
  border: 0;
  padding: $spacing-12;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::placeholder {
    color: $platform-gray-600;
  }
}

@mixin error {
  box-shadow: inset 0 0 0 1.5px $system-red-600;

  &__error {
    color: $system-red-600;
  }
}

@mixin disabled {
  background-color: $primary-gray-50;
  box-shadow: inset 0 0 0 1.5px $primary-gray-50;
  cursor: not-allowed;
}

@mixin readonly {
  background-color: $primary-gray-50;
  color: $platform-gray-700;
  box-shadow: none;
}

@mixin subText {
  @include text-misc-help;
  margin-top: $spacing-8;
  color: $platform-gray-900;
  word-break: break-word;
}

.inputField {
  @include default;

  @include withNotStates {
    @include active;
  }

  &--error {
    .inputField {
      @include error;
    }
  }

  &--disabled {
    @include disabled;
  }

  &--readonly {
    @include readonly;
  }

  &__subText {
    @include subText;
    display: flex;
    gap: $spacing-16;
    justify-content: space-between;

    &:empty {
      display: none;
    }

    &__counterText {
      flex-shrink: 0;
      margin-left: auto;
    }
  }

  &__alertBox {
    display: flex;
    background-color: $primary-gray-25;
    margin-top: $spacing-8;
    padding: $spacing-12 $spacing-16;
    align-items: flex-start;
    word-break: break-all;
    border-radius: $border-radius-2;

    &Text {
      @include text-misc-caption;
      color: $platform-gray-900;
    }

    &--withIcon {
      padding: $spacing-12 $spacing-16 $spacing-12 $spacing-12;

      > .inputField__alertBoxIcon {
        margin-right: $spacing-8;
      }
    }
  }

  &__itemsLeft,
  &__itemsRight {
    display: flex;
    align-items: center;
    gap: $spacing-4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__itemsLeft {
    left: $spacing-12;
  }

  &__itemsRight {
    right: $spacing-12;
  }
}

.inputWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.inputWrapperContent {
  position: relative;
  width: 100%;
}
