@import 'css/border-radius';
@import 'css/colors';
@import 'css/shadows';
@import 'css/spacings';
@import 'css/site-variables';

.left,
.right {
  flex: 1;
}

.left {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
  position: relative;

  .overlay {
    background: $teaserOverlay;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    margin-left: -#{$spacing-4};
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }

  .leftContentContainer {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius-4;
    box-shadow: $shadow-100;
    background-color: $platform-gray-100;
  }
}

.divider {
  height: 100%;
  border-left: $platform-gray-300 solid 1px;
}

.right {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
