@import 'css/colors';
@import 'css/border-radius';

.header {
  font-weight: 500;
  font-size: 16px;
}

.provider {
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 0.5px solid #dee2e6;
  cursor: pointer;
}

.provider:last-child {
  border-bottom: none;
}

.equityConnections {
  color: $platform-gray-700;
  padding: 50px 0px;

  .cancelButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
    color: $gray2;
    z-index: 1;
    padding: 8px;

    &:hover {
      color: $gray2;
    }
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 20px;
  }

  .providerIcon {
    max-height: 100px;
    display: block;
    border: 1px solid $gray10;
    border-radius: 50%;
    margin-bottom: 16px;
  }

  .header {
    border: none;
    padding: 5px 20px 5px 10px;
    margin: 0;
  }

  .equityConnectionsContent {
    padding: 5px 0px;
  }

  .footerText {
    color: $platform-gray-500;
    font-size: 12px;
    text-align: justify;
  }

  .equityButton {
    color: #3a4c62;
    padding: 15px;
    font-weight: 400;

    img {
      margin-right: 10px;
      width: 30px;
      border-radius: 50%;
    }

    &:hover {
      background-color: $gray8;
    }
  }

  button {
    justify-content: flex-start;
  }

  .equityConfirm {
    margin: 16px 0px;
    width: 100%;
  }
}

.bold {
  font-weight: 700;
}

.hint {
  background: #e9ecf4;
  width: 100%;
  padding: 7px 5px;
  border-radius: $border-radius-4;
}
