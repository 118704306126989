@import 'css/colors';
@import 'css/responsive';

.bannerBorder {
  display: inline-block;
  width: 100%;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-color: #f4f6fb;
}

.banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  min-height: 191px;
}

.leftContainer {
  flex: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.rightContainer {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.bannerImage {
  img {
    height: 100%;
    width: 100%;
  }
}

.textGroup {
  flex: 1;
  color: $platform-gray-700;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textGroup .title {
  color: $platform-gray-900;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 23px;

  &:first-letter {
    text-transform: uppercase;
  }
}

.text {
  color: $platform-gray-700;
  margin-bottom: 32px;
}

.buttonGroup {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.confirmButton {
  margin-right: 24px;
}

@include responsive(xs) {
  .banner {
    flex-direction: column;
  }

  .leftContainer {
    width: 100%;
    margin-bottom: 20px;
  }
}

@include media-breakpoint-up(md) {
  .banner {
    padding: 32px;
  }
}

@include media-breakpoint-up(lg) {
  .banner {
    padding: 48px;
  }
}
