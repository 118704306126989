@import 'css/colors';
@import 'css/site-variables';

.visibleCarousel {
  margin: 0 auto;
}

.item {
  display: none;

  &.activeItem {
    display: block;
  }
}

.indicators {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dots {
  background-color: $platform-gray-200;
  border: 1px solid $platform-gray-200;
  border-radius: 12px;
  margin: 8px;
  padding: 0 4px;
  height: 24px;
  display: flex;
  align-items: center;
}

button.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $gray4;
  margin: 0 4px;
  padding: 0;
  border: none;

  &.activeDot,
  &.activeDot:focus {
    background: $gray2;
  }

  &:not(.activeDot):hover {
    background: $gray5;
  }

  &:focus {
    box-shadow: none;
  }
}

button.prev,
button.next {
  background-color: $platform-gray-200;
  border-color: $platform-gray-200;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
