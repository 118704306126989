@import 'css/spacings';
@import 'css/shadows';
@import 'css/border-radius';
@import 'css/site-variables';

.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInBackdrop 150ms linear forwards;
  z-index: $z-index-max;
}

.modal {
  background-color: $neutral-full-white;
  border-radius: $border-radius-4;
  box-shadow: $shadow-800;
  animation: slideDownModal 300ms ease-out forwards;
}

@keyframes fadeInBackdrop {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba($neutral-full-black, 0.45);
  }
}

@keyframes slideDownModal {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
