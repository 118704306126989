@import 'colors';
@import 'border-radius';

@function if-important($important) {
  @return #{if($important, '!important', '')};
}

@mixin navbar-btn {
  border-radius: $border-radius-4;
  color: $light-gray;
  border: none;
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;

  &:hover {
    background-color: $platform-gray-100;
    color: $gray2;

    img {
      filter: $gray2-filter;
    }
  }
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

@mixin lineClamp($numLine: 2) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $numLine;
  -webkit-box-orient: vertical;
}

@mixin revokeButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  padding: 5px 9px;
  height: 100%;
  border: 0;

  &:focus,
  &:hover,
  &:active {
    background-color: transparent;
    box-shadow: none;
    border: 0;
  }

  img {
    width: 16px;
  }
}

@mixin closeSymbolBtn {
  align-self: flex-end;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 4px;
  color: $gray2;
  z-index: 10;

  &:hover {
    color: $gray2;
  }
}

@mixin inlineMultiSelect($width, $background-color: '#fff') {
  width: $width;
  min-width: $width;
  margin-right: 8px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  :global(.form-control) {
    border: none;
    background-color: $background-color;

    &:hover,
    &:focus {
      background-color: $platform-gray-100;
    }

    > div {
      @include ellipsis;
      max-width: calc(100% - 15px);
      padding-right: 0;
    }
  }

  :global(.dropdown-menu) {
    border: none !important;
    max-height: 500px;
    overflow-y: auto;
  }
}

@mixin dashedLinearGradient($angle, $color, $height, $spacing, $important: false) {
  background: repeating-linear-gradient(
      $angle,
      $color 0,
      $color $height,
      transparent $height,
      transparent $height + $spacing
    )
    center if-important($important);
}

@mixin stripedLinearGradient($angle, $firstColor, $secondColor, $stripeWidth, $important: false) {
  background: repeating-linear-gradient(
      $angle,
      $firstColor,
      $firstColor $stripeWidth,
      $secondColor $stripeWidth,
      $secondColor $stripeWidth + $stripeWidth
    )
    center if-important($important);
}

@mixin mainPositionMarker {
  width: 8px;
  transform: translateX(-4px);
  top: -4px;
  bottom: -4px;
  border-radius: $border-radius-4;
}

@mixin fillSvgColor($color) {
  svg {
    fill: $color;

    line,
    polyline,
    polygon,
    path,
    circle,
    rect:not(:first-child) {
      stroke: $color;
    }
  }
}

@mixin backdrop {
  display: none;
  content: '';
  position: absolute;
  z-index: $z-index-activity-log-overlay;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin upgradeButton {
  background-color: $upgrade;
  color: $neutral-full-black;

  &:hover {
    background-color: $upgrade;
  }

  &:active {
    background-color: $upgrade-active;
  }
}
