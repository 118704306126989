@import 'css/colors';
@import 'css/spacings';
@import 'js/design-system/Form/Select/ListBox.module.scss';
@import 'js/design-system/Button/Button.module.scss';
@import 'js/design-system/Text/Text.module.scss';

.tiersAndLevels {
  color: $platform-gray-700;
  display: flex;
  align-items: center;
  gap: $spacing-8;

  .left {
    display: flex;
    gap: $spacing-8;
    align-items: center;
    padding-bottom: $spacing-2;

    .circle {
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background-color: currentColor;
    }
  }

  .right {
    margin-left: auto;

    .open,
    .open:hover {
      @include activeGhostBtn;
    }

    .menu {
      top: 100%;
      right: 0;

      .subMenuContainer {
        max-width: unset !important;
      }
    }
  }
}
