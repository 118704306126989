@import 'css/colors';
@import 'css/spacings';

.hint {
  width: 100%;
  display: flex;
  gap: $spacing-8;
  align-items: center;
  padding: $spacing-12 $spacing-16 $spacing-12 $spacing-12;
  background-color: $platform-gray-100;

  > *:first-child {
    flex-shrink: 0;
  }
}
