@import 'css/bs-overrides';
@import 'css/site-variables';
@import 'css/mixins';

.input {
  position: relative;

  .wrapper {
    padding: 0;
    height: 32px;
    &:hover {
      border-color: $platform-gray-300;
    }
  }

  .toggle {
    font-weight: normal;
    display: flex;
    align-items: center;
    padding: 2px 4px 2px $input-padding-x;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    line-height: 16px;
    @include ellipsis;
    border: transparent;

    > span {
      max-width: calc(100% - 24px) !important;
      @include ellipsis;
    }

    &:hover,
    &:focus {
      border: transparent;
      color: $platform-gray-700;
    }

    &:focus {
      box-shadow: 0 0 0 1px $gray4;
    }

    &.placeholder {
      color: $platform-gray-500;
    }
  }

  .menu {
    min-width: unset;
    width: 100%;
    border-color: $platform-gray-200;
    right: 0 !important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    &Inner {
      max-height: 300px;
      overflow: auto;
      padding: 8px 0 8px 0;
    }

    &Footer {
      padding: 8px;
      border-top: 1px solid $platform-gray-200;
    }
  }

  .menu .option {
    height: 32px;
    line-height: 16px;
    color: $platform-gray-700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0;
    max-width: 300px;
    @include ellipsis;

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: $platform-gray-200;
    }

    > span {
      @include ellipsis;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .menu .active {
    font-weight: bold;
  }

  div.disabled {
    background-color: $gray10;
    border: none;
  }

  button.resetBtn {
    @include revokeButton;
  }
}
