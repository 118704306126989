@import 'css/colors';
@import 'css/spacings';

.selectWrapper {
  padding: $spacing-10;
  box-shadow: none !important;
}

.select {
  width: 150px;
  margin: 0 (-$spacing-10) 0 $spacing-8;

  input {
    box-shadow: none !important;
  }

  &:before {
    content: '';
    width: 1px;
    height: 24px;
    background-color: $platform-gray-300;
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
  }
}
