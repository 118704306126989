/* Dashboard */
$job-title-col-lg-width: 210px;
$level-col-lg-width: 64px;
$location-col-lg-width: 210px;
$comp-col-lg-width: 210px;
$range-cost-col-lg-width: 180px;
$range-position-col-lg-width: 260px;
$employee-indicator-col-width: 40px;
$employee-action-col-width: 32px;
$employee-col-sm-min-width: 210px;
$employee-row-fixed-elements-lg-width: $location-col-lg-width + $level-col-lg-width +
  $comp-col-lg-width * 2;
$employee-percentile-col-width: 64px;
$level-col-fixed-width: 168px;

/* Measurements */
$form-field-container-height: 40px;
$z-index-max: 10000;
$z-index-modal: 1055;
$navbar-height: 64px;
$banner-height: 48px;
$scenario-navbar-height: 40px;
$scenario-header-height: 48px;
$role-field-select-width: 128px;
$job-type-group-header-height: 32px;
$form-label-width: 182px;
$z-index-activity-log: 101;
$z-index-activity-log-overlay: 100;
$z-index-navbar: 500;
$base-transition-time: 350ms;
$base-transition-function: ease;
$activity-log-width: 416px;
$dropdown-in-text-field-width: 104px;
$text-field-max-width: 272px;
$text-field-min-width: 208px;
$user-menu-width: 300px;
$viewer-container-max-width: 750px;
$tooltip-width: 216px;
$cash-raise-recommendation-percentage-input-width: 118px;
