@import 'css/line-heights';

@mixin text-heading-h1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: $line-height-104;
  letter-spacing: -1.5px;
}
.text-heading-h1 {
  @include text-heading-h1;
}

@mixin text-heading-h2 {
  font-size: 4rem;
  font-weight: 400;
  line-height: $line-height-72;
  letter-spacing: -0.5px;
}
.text-heading-h2 {
  @include text-heading-h2;
}

@mixin text-heading-h3 {
  font-size: 3rem;
  font-weight: 500;
  line-height: $line-height-56;
}
.text-heading-h3 {
  @include text-heading-h3;
}

@mixin text-heading-h4 {
  font-size: 2rem;
  font-weight: 800;
  line-height: $line-height-40;
  letter-spacing: 0.25px;
}
.text-heading-h4 {
  @include text-heading-h4;
}

@mixin text-heading-h5 {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: $line-height-32;
}
.text-heading-h5 {
  @include text-heading-h5;
}

@mixin text-heading-h6 {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: $line-height-24;
  letter-spacing: 0.15px;
}

.text-heading-h6 {
  @include text-heading-h6;
}

@mixin text-subheading-large {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: $line-height-32;
  letter-spacing: 0.25px;
}
.text-subheading-large {
  @include text-subheading-large;
}

@mixin text-subheading-medium {
  font-size: 1rem;
  font-weight: 600;
  line-height: $line-height-24;
  letter-spacing: 0.15px;
}
.text-subheading-medium {
  @include text-subheading-medium;
}

@mixin text-subheading-small {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: $line-height-20;
  letter-spacing: 0.1px;
}
.text-subheading-small {
  @include text-subheading-small;
}

@mixin text-body-large {
  font-size: 1rem;
  font-weight: 400;
  line-height: $line-height-24;
  letter-spacing: 0.5px;
}
.text-body-large {
  @include text-body-large;
}

@mixin text-body-large-bold {
  @extend .text-body-large;
  font-weight: 700;
}
.text-body-large-bold {
  @include text-body-large-bold;
}

@mixin text-body-large-underline {
  @extend .text-body-large;
  text-decoration-line: underline;
}
.text-body-large-underline {
  @include text-body-large-underline;
}

@mixin text-body-large-italic {
  @extend .text-body-large;
  font-style: italic;
}
.text-body-large-italic {
  @include text-body-large-italic;
}

@mixin text-body-large-strike {
  @extend .text-body-large;
  text-decoration-line: line-through;
}
.text-body-large-strike {
  @include text-body-large-strike;
}

@mixin text-body-main {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: $line-height-20;
  letter-spacing: 0.25px;
}
.text-body-main {
  @include text-body-main;
}

@mixin text-body-main-bold {
  @extend .text-body-main;
  font-weight: 700;
}
.text-body-main-bold {
  @include text-body-main-bold;
}

@mixin text-body-main-underline {
  @extend .text-body-main;
  text-decoration-line: underline;
}
.text-body-main-underline {
  @include text-body-main-underline;
}

@mixin text-body-main-italic {
  @extend .text-body-main;
  font-style: italic;
}
.text-body-main-italic {
  @include text-body-main-italic;
}

@mixin text-body-main-strike {
  @extend .text-body-main;
  text-decoration-line: line-through;
}
.text-body-main-strike {
  @include text-body-main-strike;
}

@mixin text-button-large {
  font-size: 1rem;
  font-weight: 500;
  line-height: $line-height-24;
  letter-spacing: 0.25px;
}
.text-button-large {
  @include text-button-large;
}

@mixin text-button-medium {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: $line-height-16;
  letter-spacing: 0.25px;
}
.text-button-medium {
  @include text-button-medium;
}

@mixin text-button-small {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: $line-height-16;
  letter-spacing: 0.2px;
}
.text-button-small {
  @include text-button-small;
}

@mixin text-misc-caption {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: $line-height-16;
  letter-spacing: 0.4px;
}
.text-misc-caption {
  @include text-misc-caption;
}

@mixin text-misc-chip {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: $line-height-24;
  letter-spacing: 0.25px;
}
.text-misc-chip {
  @include text-misc-chip;
}

@mixin text-misc-eyebrow {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: $line-height-16;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.text-misc-eyebrow {
  @include text-misc-eyebrow;
}

@mixin text-misc-help {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: $line-height-16;
  letter-spacing: 0.4px;
}
.text-misc-help {
  @include text-misc-help;
}

@mixin text-misc-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: $line-height-16;
  letter-spacing: 0.25px;
}
.text-misc-input {
  @include text-misc-input;
}

@mixin text-misc-input-bold {
  @extend .text-misc-input;
  font-weight: 700;
}
.text-misc-input-bold {
  @include text-misc-input-bold;
}

@mixin text-misc-alert {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: $line-height-18;
  letter-spacing: 0.25px;
}
.text-misc-alert {
  @include text-misc-alert;
}

@mixin text-misc-nav-tabs {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: $line-height-20;
  letter-spacing: 0.1px;
}
.text-misc-nav-tabs {
  @include text-misc-nav-tabs;
}

@mixin text-badge-small {
  font-size: 0.5rem;
  font-weight: 900;
  line-height: $line-height-8;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.text-badge-small {
  @include text-badge-small;
}

@mixin text-badge-medium {
  font-size: 0.625rem;
  font-weight: 900;
  line-height: $line-height-15;
  text-transform: uppercase;
}
.text-badge-medium {
  @include text-badge-medium;
}

@mixin text-badge-large {
  font-size: 0.875rem;
  font-weight: 900;
  line-height: $line-height-16;
  text-transform: uppercase;
}
.text-badge-large {
  @include text-badge-large;
}
