.button {
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
}

.button.full {
  width: 100%;
  margin: 5px auto;
}

.button.auto {
  width: auto;
  margin: 0 10px 0 0;
}
.button.auto:last-child {
  margin: 0;
}

.actions {
  padding-top: 16px;
}
