@import 'css/mixins';
@import 'css/font';
@import 'css/spacings';
@import 'css/shadows';

.tooltipContainer {
  background-color: $neutral-full-white;
  border-radius: $border-radius-4;
  padding: $spacing-16;
  width: 250px;
  font-family: $body-font-family;

  &.withBorder {
    border: 1px solid $platform-gray-300;
    box-shadow: $shadow-300;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }

  .header {
    display: flex;
    align-items: center;
    gap: $spacing-4;
  }

  .dataTable {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-4;
    margin: $spacing-8 0;

    .tableRow {
      display: flex;
      justify-content: space-between;
      gap: $spacing-8;
    }

    .bulletCell {
      flex: 0;
      padding: 0;
    }

    .labelCell {
      flex: 1;
      min-width: 0;
      padding: 0;

      .label {
        @include ellipsis;
      }
    }

    .valueCell,
    .subValueCell {
      padding: 0;
      flex: 0;
      width: fit-content;
    }

    .subValueCell {
      min-width: 40px;
    }
  }
}
