@import 'css/site-variables';

@value formField, formLabel, formInput from "./Field.module.scss";
@value fieldLabel, fieldWrapper, uploadBtn from "./FileInput.module.scss";

.fields .formField {
  display: flex;
  align-items: flex-start;
  flex: 1;
  width: 100%;
}

.fields .formLabel {
  padding: 0 16px 0 0;
  min-width: $form-label-width;
  max-width: $form-label-width;
  min-height: calc(1.6em + 0.5rem + 2px);
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
}

.fields .formLabel label {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .fields .formField,
  .fields .formLabel,
  .fields .formInput {
    display: block;
    width: 100%;
    max-width: unset;
  }

  .fieldLabel {
    display: block;
  }

  .fieldWrapper .uploadBtn {
    margin: 0.5rem 0 0 0;
    width: 100%;
  }
}
