@import 'css/shadows';
@import 'css/border-radius';
@import 'css/spacings';
@import 'css/site-variables';

.snackbarContainer {
  z-index: 10000;
  position: fixed;
  left: $spacing-24;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
}

div.snackbar {
  height: 48px;
  width: 344px;
  background-color: $misc-snackbar-bg;
  border-radius: $border-radius-4;
  box-shadow: $shadow-100;
  color: $neutral-full-white;
  display: flex;
  align-items: center;
  padding: $spacing-8;
  box-sizing: border-box;
  transform: translateY($spacing-48);
  opacity: 1;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  // All snackbars will slide up from the bottom of the screen when instantiated.
  &.open {
    transform: translateY(-$spacing-24);
  }

  &.close {
    // Snackbars other than the bottom-most one will fade out upon closing.
    &:not(:last-child) {
      opacity: 0;
    }

    // The bottom-most snackbar will slide down upon closing.
    &:last-child {
      transform: translateY($spacing-48);
    }
  }

  .message {
    display: flex;
    align-items: center;
    flex: auto;

    .textContainer {
      margin-left: $spacing-8;
    }

    .iconContainer {
      display: flex;
      align-items: center;
      margin: 0 $spacing-4;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: $spacing-8;

    button {
      border: 0;
      color: inherit;
      font-family: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      transition: background-color $base-transition-time;

      &.actionButton {
        border-radius: $border-radius-4;
        padding: $spacing-8;
        box-sizing: border-box;
      }

      &.closeButton {
        border-radius: $border-radius-circle;
        padding: $spacing-6;
        box-sizing: border-box;
      }

      &:hover,
      &:active {
        background-color: rgba($neutral-full-white, 0.15);
      }
    }
  }
}
