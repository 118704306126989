@import 'css/spacings';
@import 'css/shadows';
@import 'css/border-radius';

.content:global(.modal-content) {
  position: relative;
  border-radius: $border-radius-4;
  box-shadow: $shadow-800;
  border: none;
  padding: 0;
}

.header {
  border-bottom: 1px solid $primary-gray-50;
  padding: $spacing-16 $spacing-16 $spacing-16 $spacing-24;
}

.footer {
  display: flex;
  border-top: 1px solid $primary-gray-50;
  justify-content: space-between;
  column-gap: $spacing-16;
  padding: $spacing-16 $spacing-24;
}
