@import 'css/spacings';
@import 'css/border-radius';

.iconContainer {
  padding: $spacing-4;
  border-radius: $border-radius-circle;
  height: fit-content;
  width: fit-content;
  display: flex;
}

.complexAlertContainer {
  display: flex;

  .contentContainer {
    margin-left: $spacing-12;
  }

  .title {
    padding-top: $spacing-2;
    padding-bottom: $spacing-4;
  }
}
