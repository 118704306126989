@import 'css/spacings';

.container {
  display: block;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;

  .rowgroup {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .cell {
    &:not(:last-of-type) {
      padding-right: $spacing-16;
    }

    &.small {
      flex: 0 0 3%;
    }

    &.medium-small {
      flex: 0.5 1;
    }

    &.medium {
      flex: 1;
    }

    &.medium-large {
      flex: 1.5;
    }

    &.large {
      flex: 2.5;
    }
  }
}
