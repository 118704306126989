@import 'css/colors';
@import 'css/shadows';
@import 'css/spacings';

.layout {
  min-height: 0;
  margin-top: $spacing-24;
}

.stickyHeaderContainer {
  position: sticky;
  z-index: 400;
  background-color: $neutral-full-white;
}

.heading {
  padding-top: 16px;
  padding-bottom: $spacing-24;
}
