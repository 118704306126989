@import 'css/site-variables';
@import 'css/spacings';
@import 'css/responsive';
@import 'css/colors';

.scenarioPickerContainer {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.iconButton {
  color: $platform-gray-400 !important;

  &:hover {
    color: $platform-gray-900 !important;
  }
}

.container {
  position: sticky;
  top: 0;
  z-index: $z-index-navbar;

  .navBar {
    display: flex;
    padding: $spacing-16 0;
    height: $navbar-height;
    background-color: $platform-gray-900;

    &__totalRewards {
      padding: $spacing-12 0;
      height: auto;
    }

    .separator {
      width: $spacing-1;
      height: 100%;
      background-color: $platform-gray-800;
    }

    .navContainer {
      padding: 0 $spacing-24;
      flex: auto;
      display: flex;
      justify-content: space-between;

      .oneRow {
        display: flex;
        align-items: center;
        gap: $spacing-16;

        .logoMain {
          width: 32px;
          height: 32px;
        }

        .logoWide {
          height: 40px;
        }

        .companyName {
          font-weight: bold;
          font-size: 20px;
          color: white;
        }
      }
    }
  }
}

@include responsive(xxs) {
  .logo {
    display: none;
  }
}
