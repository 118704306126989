@import 'css/colors';
@import 'css/spacings';
@import 'js/design-system/Form/Select/ListBox.module.scss';
@import 'js/design-system/Text/Text.module.scss';
@import 'js/design-system/Button/Button.module.scss';

.displaySettingsContainer {
  position: relative;
  max-width: fit-content;
}

.menuOpen,
.menuOpen:hover {
  @include activeGhostBtn;
}

.menu {
  @include listbox;
  width: 248px;
  padding: 0;
  top: 100%;
  right: 0;

  .itemsPlaceholder {
    max-height: 380px;
    overflow-y: auto;

    .item {
      position: relative;
      padding: $spacing-16 $spacing-16 $spacing-12 !important;
      color: $neutral-full-black;
      border-bottom: 1px solid $primary-gray-200;

      &:last-child {
        border-bottom: none;
      }

      .label label {
        @include text-misc-caption;
        color: $platform-gray-900;
      }

      .itemOptions {
        position: relative;
        right: $spacing-8;
      }
    }
  }

  .actionsContainer {
    border-top: 1px solid $primary-gray-200;
    padding: $spacing-16;

    .actionButton {
      width: 100%;
    }
  }
}
