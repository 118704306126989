@import 'css/colors';
@import 'css/spacings';

.heading {
  display: flex;
  column-gap: $spacing-8;
  align-items: center;
}

.avatar {
  outline: $spacing-4 solid $neutral-full-white;
}

.text_comment {
  white-space: pre-line;
}

.activity {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: $spacing-40;
  padding-bottom: $spacing-24;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    top: 0;
    left: $spacing-16 - 1px;
    height: 100%;
    background-color: $primary-gray-25;
  }

  &:first-child {
    &::before {
      top: unset;
      bottom: -$spacing-12;
      height: calc((100% - $spacing-24) / 2 + $spacing-24);
    }
  }

  &:last-child {
    &::before {
      display: none;
    }
  }
}

.activity__left {
  position: absolute;
  z-index: 7;
  top: 0;
  left: 0;
}

.activity__right {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
}
