@import 'css/colors';
@import 'css/spacings';

.container {
  padding: $spacing-24;
}

.divider {
  border-color: $platform-gray-200;
  opacity: 1;
  margin: $spacing-24 0;
}

.formHeader {
  display: flex;
  gap: $spacing-16;

  .headerText {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }
}

.formContent {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
}

.formActions {
  padding: 0;
  margin-top: $spacing-24;
  display: flex;
  align-items: center;
  gap: $spacing-16;
}
