@import 'css/border-radius';
@import 'css/spacings';

.tabHeader {
  border-top-left-radius: $border-radius-4;
  border-top-right-radius: $border-radius-4;
  overflow: hidden;
}

.resultsContainer {
  padding-top: $spacing-12;
  display: flex;
  flex-direction: column;
  height: 100%;

  .resultsText {
    margin-bottom: $spacing-40;
  }

  .resultsTitleContainer {
    display: flex;
    align-items: center;
    gap: $spacing-8;
  }
}

.ctaContainer {
  height: 100%;
  display: flex;
}

.footerText {
  margin-top: $spacing-16;
}
