@import 'css/border-radius';
@import 'css/colors';
@import 'css/shadows';
@import 'css/spacings';
@import 'css/site-variables';
@import 'src/js/design-system/Text/Text.module.scss';

$list-box-max-height: 265px;
$filterable-menu-max-height: 335px; // completely arbitrary number to ensure that the last item in the list is cut off, indicating to the user that there are options to scroll

@mixin listbox {
  display: none;
  position: absolute;
  padding: $spacing-8 0;
  background-color: $neutral-full-white;
  border: solid 1px $primary-gray-50;
  border-radius: $border-radius-4;
  box-shadow: $shadow-300;
  z-index: $z-index-max;

  &.open {
    display: flex;
    flex-direction: column;
  }

  // The `overflow` property breaks the positioning of flyout menus,
  // so we only set height & overflow on flat listbox options.
  &:not(.hasNested) .itemsContainer {
    max-height: $list-box-max-height;
    overflow-y: auto;
    position: relative;
  }

  .divider {
    border-top: solid 1px $primary-gray-200;
    margin: $spacing-8 0;
    width: 100%;
  }
}

@mixin listboxItem {
  @include text-misc-input;
  padding: $spacing-16 $spacing-12;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: $spacing-8;
  position: relative;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    background-color: $primary-gray-50;
  }

  // Permit hover (for tooltips) but disallow clicking
  &.disabled {
    cursor: not-allowed;
    background-color: transparent;
    color: $platform-gray-500;

    &:active,
    &:focus {
      pointer-events: none;
    }
  }

  &.selected {
    font-weight: 700;
  }
}
