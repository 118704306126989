@import 'css/site-variables';
@import 'css/colors';
@import 'css/spacings';

.navbarContainer {
  background-color: $platform-gray-700;
  z-index: 90;
  overflow: hidden;
}

.navbarContainer .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: $scenario-navbar-height;
  padding: 0 $spacing-24;
}
