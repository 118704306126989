@import 'css/colors';
@import 'css/spacings';
@import 'js/design-system/Text/Text.module.scss';

.recentQueriesMenu {
  width: 300px;
}

.option {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  .top {
    @include text-misc-input-bold;
    display: flex;
    align-items: center;
    gap: $spacing-8;
  }

  .bottom {
    @include text-misc-help;
    color: $platform-gray-700;
    display: flex;
    align-items: center;
    gap: $spacing-8;

    .circle {
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background-color: currentColor;
    }
  }
}

.active {
  background-color: $primary-gray-300 !important;
  color: $platform-gray-900 !important;
}
