@import 'css/spacings';
@import 'css/site-variables';

.inputContainer {
  width: 100%;
}

.leftLabel {
  display: flex;

  .label {
    margin: 0 $spacing-16 0 0;
    height: $form-field-container-height;
  }
}

// right labels are a special case for checkboxes
.rightLabel {
  display: flex;
  flex-direction: row-reverse;
  gap: $spacing-12;
  align-items: center;
  justify-content: flex-end;

  .label {
    margin: 0;

    > * {
      display: flex;
      align-items: center;
    }
  }
}
