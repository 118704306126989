@import 'css/border-radius';
@import 'css/colors';
@import 'css/spacings';

.userList {
  display: flex;
}

.avatar {
  border: solid 2px $neutral-full-white;
  border-radius: $border-radius-circle;

  &:not(:first-of-type) {
    margin-left: -$spacing-10;
  }

  &.more {
    color: $neutral-full-white;
    background-color: $platform-gray-400;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
