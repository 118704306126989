@import 'css/colors';
@import 'css/site-variables';
@import 'css/border-radius';
@import 'css/spacings';

.percentileInputField {
  width: 100%;
  display: flex;
  background: white;
  border-radius: 0.25rem;

  .button {
    width: $form-field-container-height;
    height: $form-field-container-height;
  }

  > input {
    text-align: center;
    color: $platform-gray-500;
    background-color: $primary-gray-25;
    margin: 0 $spacing-8;
    border-radius: $border-radius-4;
    min-width: 56px;
    height: $form-field-container-height;

    &.dirty {
      color: $platform-gray-700;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      cursor: default;
      outline: none;
    }

    &.disabledInput {
      background-color: $primary-gray-25 !important;
    }
  }
}
