@import 'css/spacings';

.strongPasswordIcon {
  margin-right: $spacing-4;
}

.checkedLabel {
  display: flex;
  align-items: center;
}
