@import 'css/colors';

.confirmBtn {
  background-color: $red-500;

  &:disabled {
    background-color: $red-400;
  }

  &:hover,
  &:focus {
    background-color: $red-600;
  }

  &:active {
    background-color: $red-700;
  }
}
