.section {
  margin: 40px 0;
}
.section:first-of-type {
  margin-top: 0;
}

.header {
  font-weight: 700;
  font-size: 1.875rem;
  color: #3a4c62;
}

.subHeader {
  font-size: 1rem;
  font-weight: normal;
  color: #596d84;
}

.content {
  padding-top: 25px;
}
