@import 'css/spacings';
@import 'css/mixins';
@import 'js/design-system/Text/Text.module.scss';

.label {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  width: 100%;

  .employeeData {
    display: flex;
    gap: $spacing-8;
    align-items: center;
  }

  .jobRole {
    @include ellipsis;
  }

  .location {
    white-space: nowrap;
  }
}

.disabled {
  color: $platform-gray-400 !important;
}
