@import 'css/spacings';
@import 'css/colors';

.menu {
  width: 256px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  user-select: none;

  .viewRangesTitle {
    display: flex;
    gap: $spacing-4;
  }

  &.needUpgrade {
    .text {
      opacity: 0.5;
    }
  }
}

.trigger {
  color: $neutral-full-white;
  box-shadow: inset 0 0 0 1px rgba($neutral-full-white, 0.25);

  &:hover {
    color: $neutral-full-white;
    box-shadow: inset 0 0 0 1px rgba($neutral-full-white, 0.3);
    background-color: rgba($neutral-full-white, 0.1);
  }

  &:active,
  &.open {
    box-shadow: inset 0 0 0 1px rgba($neutral-full-white, 0.53);
    background-color: rgba($neutral-full-white, 0.12);
  }
}
