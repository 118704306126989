@import 'css/spacings';

.wrapper:global(.modal-dialog) {
  max-width: 558px;
  width: 100%;
}

.wrapper .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: $spacing-64 $spacing-96 $spacing-56;
}

.title {
  margin-bottom: $spacing-8;
}

.description {
  margin-bottom: $spacing-32;
}

.button {
  justify-content: center;
  width: 100%;
}

.buttonWrapper {
  display: flex;
  width: 100%;
  row-gap: $spacing-8;
  flex-direction: column;
}

.icon {
  margin-bottom: $spacing-24;
}
