@import 'css/colors';
@import 'css/mixins';
@import 'css/responsive';

.shareBtn {
  color: $platform-gray-400;
  box-shadow: none;

  &:hover {
    color: unset;
  }
}

@include responsive(xs) {
  .shareBtn {
    display: none;
  }
}
