$blue: #337ab7;
$red: #dc3545;
$yellow: #ffc107;
$green: #198754;
$teal: #14dea2;
$cyan: #0dcaf0;

$gray1: #030e25;
$gray2: #3a4c62;
$gray2-filter: invert(28%) sepia(15%) saturate(1163%) hue-rotate(173deg) brightness(88%)
  contrast(85%);
$gray3: #596d84;
$gray4: #7d93ad;
$gray4-filter: invert(59%) sepia(27%) saturate(338%) hue-rotate(172deg) brightness(92%)
  contrast(90%);
$gray5: #9baec7;
$gray6: #c5d0df;
$gray8: #e7eef8;
$gray9: #f5f7fb;
$gray10: #e0e5ef;
$white: #ffffff;
$gray-300: #dee2e6;

$primary: hsla(223, 90%, 62%, 1);
$primary-filter: invert(46%) sepia(9%) saturate(6273%) hue-rotate(194deg) brightness(97%)
  contrast(99%);
$primary-hover: hsla(223, 66%, 47%, 1);
$secondary: $blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$danger-hover: #bb2d3b;
$light: $gray9;
$dark: $gray1;

$tier-3: #8b5fe6;
$tier-2: #00abf2;
$tier-1: $primary;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'tier-3': $tier-3,
  'tier-2': $tier-2,
  'tier-1': $tier-1,
  'gray': $gray4,
  'light-gray': $gray10,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1200px,
  xxl: 1400px,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 2,
  5: $spacer * 3,
);

$container-max-widths: (
  sm: 540px,
  md: 770px,
  lg: 960px,
  xl: 1200px,
  xxl: 1320px,
);

$form-text-color: $gray2;
$form-label-color: $gray2;
$form-label-font-weight: 500;

$input-border-color: #e5e5e5;
$input-padding-x: 0.5rem;
$input-padding-y: 0.25rem;
$input-line-height: 1.6;
$input-border-radius: 0.25rem;
$input-color: $gray2;
$input-box-shadow: 0 0 0 0.25rem rgb(7 157 125 / 25%);
$input-focus-box-shadow: 0 0 0 0 transparent;
$input-focus-border-color: $gray4;

$link-color: $blue;
$link-decoration: none;

$dropdown-min-width: 8rem;
$enable-validation-icons: false;
$min-contrast-ratio: 3;
