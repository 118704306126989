.picker {
  height: 16px;
  width: 16px;
  padding: 0;
  border: none;
  border-radius: 2px;

  &::-webkit-color-swatch {
    border: none;
    border-radius: 2px;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}
